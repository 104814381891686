import React, { useContext, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'
import Known from '../assets/known.png'
import upcome from '../assets/upcome.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {
  GetEvents,
  GetFlyer,
  GetProphecy,
  GetTestimonies,
  GetVideos,
} from '../api/api'
import { Calendar, CallUser, Hero, User, Video } from '../asset'
import CarouselContainer from '../components/Carousel'
import { eventsContext } from '../context/event'
import { testimonyContest } from '../context/testimonies'
import { videosContest } from '../context/videos'
import { flyerContext } from '../context/flyer'
import { fDate, getDay, getMonth, getTime } from '../utils/format'
import { prophecyContest } from '../context/prophecy'

import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { IoIosArrowRoundForward } from 'react-icons/io'

const Home = () => {
  const [flyer, setFlyer] = useContext(flyerContext)
  const [videos, setVideos] = useContext(videosContest)
  const [events, setEvents] = useContext(eventsContext)
  const [testimonies, setTestimonies] = useContext(testimonyContest)
  const [proclamation, setProclamation] = useContext(prophecyContest)
  const s = useNavigate()

  const getFlyer = GetFlyer()
  const getVideos = GetVideos()
  const getEvents = GetEvents()
  const getTestimonies = GetTestimonies()
  const getProclamation = GetProphecy()

  useEffect(() => {
    getFlyer((data) => setFlyer(data))
    getTestimonies((data) => setTestimonies(data))
    getProclamation((data) => setProclamation(data))
    getVideos((data) => setVideos(data))
    getEvents((data) => setEvents(data))
    AOS.init()
  }, [])
  console.log(events)
  return (
    <>
      {/* hero */}
      <div
        style={{
          background: `url(${Hero})`,
          backgroundBlendMode: 'multiply',
          backgroundColor: '#222e50',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        className='flex relative  items-center justify-center z-10  h-96 sm:h-[26rem] md:h-[34rem]  lg:h-[38rem] xl:h-[100vh]'
      >
        <div
          style={{ zIndex: 9 }}
          className='w-5/6 lg:w-3/4 pt-[4rem] md:pt-[6rem] flex flex-col items-center mx-auto space-y-6 sm:space-y-10  md:space-y-14  lg:space-y-20 '
        >
          <div className='flex flex-col items-center mx-auto -space-y-3 lg:-space-y-5'>
            <p
              data-aos='fade-down'
              data-aos-duration='2000'
              data-aos-delay='200'
              className='text-white font-sans text-center text-sm max-w-xs sm:text-base md:text-xl md:max-w-md lg:text-2xl xl:text-3xl xl:max-w-full '
            >
              Welcome to Glorious Nation Church
            </p>
            <h1
              data-aos='fade-down'
              data-aos-duration='2000'
              data-aos-delay='800'
              className='text-white font-mono  text-2xl max-w-xs sm:max-w-sm sm:text-3xl text-center md:text-4xl md:max-w-lg lg:max-w-xl lg:text-5xl xl:text-6xl xl:max-w-3xl '
            >
              Experience the presence of God in its fullness
            </h1>
          </div>

          <button
            onClick={() => s('/live')}
            className='text-babypurple font-sans px-3 py-2 rounded text-xs sm:text-sm bg-white md:px-4 md:text-base lg:py-3 xl:text-lg xl:px-5 font-bold hover:bg-babypurple hover:text-white cursor-pointer duration-1000 shadow shadow-white'
          >
            Worship with us
          </button>
        </div>
        <div className='video__content'>
          <video
            preload='true'
            loop
            controls={false}
            autoPlay
            muted
            className='video-bg'
          >
            <source src={Video} type='video/mp4' />
          </video>
        </div>
        <div className='absolute top-0 left-0 right-0 z-20'>
          <Navbar />
        </div>
      </div>
      {/* get to know */}
      <section className='section-center'>
        {/* cont */}
        <div className='mt-16 md:mt-10 lg:mt-16 space-y-10 sm:space-y-12 md:space-y-0 md:flex items-center justify-between gap-6 lg:gap-10'>
          {/* image */}
          <div className='flex justify-center items-center md:w-1/2 '>
            <img
              src={Known}
              alt=''
              className=' w-54 sm:w-full sm:max-w-xs lg:max-w-sm xl:max-w-md  '
            />
          </div>
          {/* text */}
          <div className='md:w-1/2 space-y-6 md:space-y-5 xl:space-y-6'>
            {/* headline */}
            <div>
              <h1
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='200'
                className='text-babyblack text-xl font-bold relative w-max sm:text-2xl md:text-xl lg:text-2xl mx-auto  xl:text-3xl text-center md:text-left md:mx-0'
              >
                Get to Know
                <span className='text-[#A303A0]'> Us </span>
              </h1>
              <h1 className='text-center text-base text-babyblack  font-bold sm:text-xl  md:text-left xl:text-3xl md:max-w-xs xl:max-w-md font-mono tracking-wide'>
                A brief introduction about us
              </h1>
            </div>

            <p
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='600'
              className='text-justify text-sm sm:text-base md:text-sm md:text-left md:max-w-md lg:text-base xl:text-base'
            >
              We are a Bible believing, Holy Spirit Filled Church. We preach the
              Gospel of Jesus Christ, the one and only Access to the Father
              (John 14:6 Jesus answered, "I am the way and the truth and the
              life. No one comes to the Father except through me. NIV). We have
              a mandate to Preach the Evangelion of Jesus Christ (Mark 16:15),
              to reconcile the lost sheep to the Father (2 Corinthians 5:18). We
              are here to Recuperate, Readdress and Endow; (Colossians 1:27 To
              whom God would make known what the riches of the glory of this
              mystery among the Gentiles is; which is Christ in you, the hope of
              glory).
            </p>
            <button
              onClick={() => s('/about')}
              className='flex justify-between items-center border border-babypurple text-babypurple  gap-2 px-4 py-1 lg:py-2 md:mx-0 mx-auto hover:text-white hover:bg-babypurple duration-1000'
            >
              <p className='mb-0 font-bold'>Read More</p>
              <IoIosArrowRoundForward className='text-3xl block font-bold' />
            </button>
          </div>
        </div>
      </section>
      {/* upcoming Events */}
      <section className='section-center'>
        {/* bidy */}
        <div>
          {/* new title */}
          <div className=' section-center my-20 space-y-10 md:flex md:justify-between md:items-center md:space-y-0 md:gap-10 lg:gap-14 xl:gap-20  overflow-hidden'>
            {/* image */}
            <div
              data-aos='fade-right'
              data-aos-duration='2000'
              data-aos-delay='400'
              className='  relative md:w-1/2'
            >
              <img
                src={upcome}
                alt='logo'
                width={1000}
                height={1000}
                className='w-72  sm:w-80 md:w-full lg:w-full max-w-xs sm:max-w-sm lg:max-w-full mx-auto '
              />
            </div>
            {/* text */}
            <div className='font-sans flex flex-col justify-center items-center mx-auto md:mx-0 md:items-start space-y-6 md:w-1/2 '>
              {/* headline */}
              {/* headline */}
              <div>
                <h1
                  data-aos='fade-up'
                  data-aos-duration='2000'
                  data-aos-delay='200'
                  className='text-babyblack text-xl font-bold relative w-max sm:text-2xl md:text-xl lg:text-2xl mx-auto  xl:text-3xl text-center md:text-left md:mx-0'
                >
                  Upcoming
                  <span className='text-[#A303A0]'> Events </span>
                </h1>
                <h1 className='text-center text-base text-babyblack  font-bold sm:text-xl  md:text-left xl:text-3xl md:max-w-xs xl:max-w-md font-mono tracking-wide mb-0'>
                  Here’s a sneak pick of our upcoming events
                </h1>
              </div>

              {/* paragraph */}
              <p
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='600'
                className='text-babyblack text-xs  text-center sm:text-sm sm:leading-5 md:text-left md:leading-5 xl:text-base xl:max-w-md'
              >
                Our church events and programs will be regularly updated on this
                section of our website so you never have to miss out on any of
                our power packed programs
              </p>
              {/* details */}
              <div
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='800'
                className='md:max-w-full xl:max-w-md space-y-4  sm:space-y-8'
              >
                {/* one */}
                {events
                  .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                  .slice(0, 3)
                  .map((item, index) => {
                    return (
                      <div
                        key={index}
                        className='bg-white shadow-md shadow-babygreen flex gap-4 items-start px-4 py-6 md:hover:translate-x-6 hover:shadow-xl duration-1000 '
                      >
                        {/* icon */}
                        <div className='p-2 bg-babypurple w-max rounded-sm  text-white font-bold  '>
                          {getDay(item?.date)}
                        </div>
                        {/* text */}
                        <div className='space-y-1'>
                          <h1 className=' text-base font-bold'>
                            {item?.event_title}
                          </h1>
                          <p className='text-xs'>
                            Join us on{' '}
                            <span className='font-bold'>
                              {' '}
                              {getDay(item?.date)} {getMonth(item?.date)}
                            </span>{' '}
                            for our{' '}
                            <span className='font-bold'>
                              {item?.event_title}{' '}
                            </span>
                            program. This program will be aired live on this
                            website and on our Youtube channel by{' '}
                            <span className='font-bold'>
                              {getTime(item?.date)}{' '}
                            </span>{' '}
                            time.
                          </p>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* previous sermon */}
      <section className='section-center'>
        {/* bidy */}
        <div className='space-y-10 sm:space-y-12 md:space-y-20 '>
          {/* title */}
          <div className='flex flex-col justify-center items-center space-y-2 '>
            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='200'
              className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
            >
              Previous <span className='text-babypurple'> Sermon </span>
            </h1>
            {/* top */}
            <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
              Here’s a recap of some of our past power packed sermons
            </h2>
          </div>

          {/* cont */}
          <div
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='400'
            className='space-y-14 flex flex-col justify-center items-center mx-auto max-w-xs sm:max-w-md md:grid md:grid-cols-2 w-full md:max-w-full md:space-y-0 md:gap-10 xl:grid-cols-3'
          >
            {videos
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              .slice(0, 3)
              .map((item, index) => (
                <div key={index} className='space-y-2 '>
                  <p className='text-sm mb-2 font-semibold'>{item?.title}</p>
                  <div
                    className='bg-gray-200'
                    style={{ width: '100%', height: '220px' }}
                  >
                    <ReactPlayer
                      controls
                      width={'100%'}
                      height={'100%'}
                      url={item?.video_url}
                    />
                  </div>
                  <div className='flex mt-4 justify-between items-center'>
                    <div className='flex items-center'>
                      <img src={User} alt='pastor' />
                      <p className='text-[9px] sm:text-xs mb-0 font-bold ml-2'>
                        {item?.preacher}
                      </p>
                    </div>
                    <div className='flex items-center'>
                      <img src={Calendar} alt='Calendar' />
                      <p className='text-[9px] sm:text-xs mb-0 font-medium ml-2'>
                        {fDate(item?.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className='flex justify-center items-center mx-auto'>
            <button
              onClick={() => s('/live')}
              className='flex justify-between items-center border border-babypurple text-babypurple  gap-2 px-4 py-1 lg:py-2 md:mx-0 mx-auto hover:text-white hover:bg-babypurple duration-1000'
            >
              <p className='mb-0 font-bold'>See All</p>
              <IoIosArrowRoundForward className='text-3xl block font-bold' />
            </button>
          </div>
        </div>
      </section>
      {/* feat prog */}
      <section className='section-center'>
        {/* bidy */}
        <div className='space-y-10 sm:space-y-12 md:space-y-20 '>
          {/* title */}
          <div className='flex flex-col justify-center items-center space-y-2 '>
            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='400'
              className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
            >
              Featured <span className='text-babypurple'> Programs </span>
            </h1>
            {/* top */}
            <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
              Here's a list of our upcoming featured programs
            </h2>
          </div>

          {/* carousel */}
          <div
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='600'
            className='mt-[4rem]'
          >
            <CarouselContainer maxSize={3}>
              {flyer
                .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                .map((item, index) => (
                  <div key={index}>
                    <div className='max-w-xs md:w-72 xl:w-64  lg:max-w-sm   mx-auto py-4'>
                      <img
                        className='w-full h-full object-cover shadow-lg rounded '
                        src={item.image_url}
                        alt='flyer'
                      />
                    </div>
                  </div>
                ))}
            </CarouselContainer>
          </div>
        </div>
      </section>
      {/*salvation prayer  */}
      <section>
        {/* new */}
        <div className='back-image  h-[34rem] sm:h-[30rem] md:h-[30rem] lg:h-[30rem] 2xl:h-[32rem] flex flex-col justify-center items-center text-white space-y-2 md:space-y-4 xl:space-y-6 px-4 sm:px-6 md:px-8 lg:px-10 py-4 xl:px-12 2xl:px-14'>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-3xl font-sans max-w-xs text-center sm:max-w-sm sm:text-4xl md:text-4xl md:max-w-lg xl:text-6xl lg:max-w-3xl  tracking-wide text-white '
          >
            Salvation Prayer
          </h1>
          <p
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='500'
            className='font-sans text-justify text-xs tracking-wide sm:leading-5 md:leading-6 lg:text-sm lg:leading-7 2xl:text-base  2xl:leading-8 2xl:max-w-6xl max-w-xs sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl'
          >
            Father, in Jesus name I come to You tonight and I come as a sinner,
            I confess my sins Lord, I repent of my sins; that means I turn my
            back on them. I have made up my mind that I am going to serve the
            Lord and make Heaven my home. I am through with the world, I am
            through with the flesh! Devil hear me, I am through with you. I
            choose Jesus Christ as my Lord and Savior. I believe with my heart
            that Jesus is Lord. I believed HE died on Calvary, I believe HE was
            buried and according to the Scriptures, I believe He arose from the
            dead, HE ascended into Heaven, and Sat down at the Right Hand of the
            Father! Jesus, You represent me there, from tonight on, I want to
            represent You here but Lord I am weak, I confess that to You! So I
            invite You to come into my life. By Your Spirit, work in me, talk in
            me; be my God, let me be Your Child! Thank You, Lord! I believe with
            my heart, confess with my mouth! You said I am saved, I believe
            that! You hear that devil! I am saved and you know that I am! Thank
            You, Jesus! In Jesus Christ's Mighty Name! If you have said this
            prayer; welcome to the family of God! Right now God doesn't even
            remember what you use to be! All HE can see is the blood! Now
            listen, I love you too much to tell you to go to any Church of your
            choice! Cus if I say so you might return to a cold death one! Get
            out of there and find a hot Holy Spirit-filled church.
          </p>
        </div>
      </section>
      {/* testimonies */}
      <section className='section-center'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Our <span className='text-babypurple'> Testimonies </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Get edified by these Testimonies
          </h2>
        </div>

        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='400'
          className='pt-[3rem]'
        >
          <CarouselContainer maxSize={3}>
            {testimonies
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              .map((item, index) => (
                <div key={index}>
                  <div className='w-full  sm:w-[90%] md:max-w-xs mx-auto h-full border-2 border-[#A303A0] shadow rounded-md sm:h-[320px] overflow-hidden p-5 flex flex-col justify-between items-center'>
                    <div className='flex mb-[1rem] items-center'>
                      <div className='text-center flex justify-center items-center mx-auto'>
                        <h2 className='text-xl mb-1 font-bold '>
                          {item?.title}
                        </h2>
                      </div>
                    </div>
                    <p className=' text-sm text-justify'>{item?.content}</p>
                    <div className='text-center'>
                      <p className='text-[#A303A0] mb-0 font-semibold text-base'>
                        {item?.sender_name},
                      </p>
                      <p className='text-babyblack mb-0  text-xs'>
                        {item?.country}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </CarouselContainer>
        </div>
        <div className='flex justify-center items-center mx-auto'>
          <button
            onClick={() => s('/connect')}
            className='flex justify-between items-center border border-babypurple text-babypurple  gap-2 px-4 py-1 lg:py-2 md:mx-0 mx-auto hover:text-white hover:bg-babypurple duration-1000 mt-20'
          >
            <p className='mb-0 font-bold'>Add Yours</p>
            <IoIosArrowRoundForward className='text-3xl block font-bold' />
          </button>
        </div>
      </section>
      {/* faith procalamation */}
      <section className='section-center'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Faith <span className='text-babypurple'> Proclamations </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Renew your faith by affirming these proclamations
          </h2>
        </div>

        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='400'
          className='mt-[2rem] md:mt-[4rem]'
        >
          <CarouselContainer maxSize={3}>
            {proclamation
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              .map((item, index) => (
                <div key={index}>
                  <div className='max-w-xs md:w-80 lg:w-72  lg:max-w-sm   mx-auto py-4 '>
                    <img
                      className='w-full object-contain shadow-lg rounded'
                      src={item?.image_url}
                      alt='flyer'
                    />
                  </div>
                </div>
              ))}
          </CarouselContainer>
        </div>
      </section>

      {/* get in touch */}
      <section className='section-center'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1 className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'>
            Connect with <span className='text-babypurple'> Us </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Take a first step into our community
          </h2>
        </div>

        {/* new */}
        <div id='about' className='mt-10 space-y-10   '>
          {/* small */}
          {/* image */}
          <div
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='md:max-w-lg lg:max-w-xl mx-auto xl:hidden'
          >
            <img src={CallUser} alt='' />
          </div>
          {/* text */}
          <div
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='400'
            className='space-y-3 md:space-y-4 flex flex-col justify-center items-center xl:hidden '
          >
            <h3 className='mt-0 text-xl sm:text-2xl md:text-2xl lg:text-3xl text-center  font-bold'>
              We Would love to hear from you !
            </h3>
            <p className='text-center text-sm sm:text-base max-w-xs sm:max-w-md md:text-base md:text-center md:max-w-lg lg:text-lg lg:max-w-xl'>
              The journey of christiandom can be a tideous one and one we
              wouldn’t like to go alone. Do you have a testimony, prayer
              request, inquiry or information you would like to share with us,
              then feel free to reach out to us and we would get back to you as
              soon as we possibly can
            </p>
            <button
              onClick={() => s('/connect')}
              className='bg-[#A303A0] px-4 py-2 md:px-4 md:py-2 xl:px-6 xl:py-2  font-bold   rounded-sm  hover:bg-veryLightGray duration-1000  hover:text-white xl:rounded-md tracking-wide  mx-auto md:mx-0 flex justify-center items-center cursor-pointer text-white'
            >
              Let's Connet
            </button>
          </div>

          {/* big */}
          <div className='hidden xl:flex relative max-w-5xl xl:flex-col justify-center items-start'>
            {/* image */}
            <div
              data-aos='fade-left'
              data-aos-duration='2000'
              data-aos-delay='200'
              className='max-w-2xl 2xl:max-w-3xl '
            >
              <img src={CallUser} alt='' />
            </div>
            {/* text */}
            <div
              data-aos='fade-right'
              data-aos-duration='2000'
              data-aos-delay='400'
              className=' absolute left-[34rem] right-0  bottom-16 space-y-6 md:space-y-4 flex flex-col justify-start items-start bg-darkBlue text-white max-w-md  py-4 px-6 mx-0 2xl:px-10 2xl:py-8 '
            >
              <h3 className='mt-0 text-white text-2xl text-left '>
                We Would love to hear from you !
              </h3>
              <p className=' text-sm text-left '>
                The journey of christiandom can be a tideous one and one we
                wouldn’t like to go alone. Do you have a testimony, prayer
                request, inquiry or information you would like to share with us,
                then feel free to reach out to us and we would get back to you
                as soon as we possibly can
              </p>
              <button
                onClick={() => s('/connect')}
                className='bg-white px-4 py-2 md:px-4 md:py-2 xl:px-6 xl:py-2  font-bold   rounded-sm  hover:bg-babypurple duration-1000  hover:text-white xl:rounded-md tracking-wide  mx-auto md:mx-0 flex justify-center items-center cursor-pointer text-[#A303A0]  '
              >
                Let's Connet
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Home
