import { format } from 'date-fns';

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}
export function getDay(date) {
  return format(new Date(date), 'dd');
}
export function getMonth(date) {
  return format(new Date(date), 'MMMM');
}

export function getTime(date){
  const preDateTime = new Date(date)
  let newTime = preDateTime.toLocaleTimeString('en-US');
  let hour = newTime.split(":")[0];
  let amPm = newTime.split(" ")[1];
  let seconds = newTime.split(":")[2].replace(amPm,'');;

  let noSeconds = newTime.replace(":"+seconds,' ');

  if(parseInt(hour)<9){
      noSeconds= "0"+noSeconds
  }
  return noSeconds
}