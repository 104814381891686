import React, { useContext, useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { GetVideos, GetLive } from '../api/api'
import { Calendar, User } from '../asset'
import { videosContest } from '../context/videos'
import { fDate } from '../utils/format'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// import './home.css'

function Live() {
  const [videos, setVideos] = useContext(videosContest)

  const getVideos = GetVideos()
  const getLive = GetLive()
  const [isLive, setIsLive] = useState(false)
  const [frame, setFrame] = useState(null)

  useEffect(() => {
    getLive((data) => {
      if (data.length > 0) {
        console.log(data[0].url)
        setFrame(data[0].url)
        setIsLive(true)
      } else {
        setFrame(null)
        setIsLive(false)
      }
    })
    getVideos((data) => setVideos(data))
    return () => {
      setFrame(null)
      setIsLive(false)
    }
  }, [getLive, getVideos, setVideos])
  return (
    <>
      <div className='flex flex-col w-full overflow-hidden'>
        {isLive ? (
          <div className='iframe-bg h-auto'>
            <div className='iframe-container h-[57vw] lg:h-[85vh] w-[100vw] lg:w-[calc((90vh * .75) + 95.5vh)]'>
              <iframe
                src={
                  'https://www.facebook.com/plugins/video.php?show_text=false&t=0&href=' +
                  frame
                }
                title='Play'
                className='iframe'
                scrolling='no'
                frameBorder='0'
                allowFullScreen={true}
                allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
              ></iframe>
            </div>
          </div>
        ) : (
          <div className='backd-image  h-[26rem] md:h-[34rem]  lg:h-[38rem] xl:h-[100vh]   flex flex-col justify-center items-center text-white space-y-2 sm:space-y-4  xl:space-y-6 px-2 '>
            <div className='absolute top-0 left-0 right-0 z-20'>
              <Navbar />
            </div>
            <h1 className='text-3xl font-mono max-w-xs text-center sm:max-w-sm sm:text-4xl md:text-5xl md:max-w-lg lg:text-5xl  xl:text-6xl lg:max-w-xl  tracking-wide text-white '>
              We aren’t live at the Moment
            </h1>
            <p className='font-sans text-white font-normal text-center max-w-xs sm:max-w-sm md:text-lg md:max-w-md lg:text-xl lg:max-w-lg xl:text-2xl xl:max-w-xl 2xl:text-3xl 2xl:max-w-2xl'>
              We do not have a live session going on at the moment, please do
              well to visit our homepage for updates on future programme
              schedules
            </p>
            {/* call to action */}
            {/* <div className='flex justify-center items-center '>
              <button
                onClick={() => s('/')}
                className='bg-[#a303a0] px-4 py-2 md:px-4 md:py-2 xl:px-6 xl:py-3 2xl:px-7   font-bold  text-white  rounded-sm  hover:bg-veryLightGray duration-1000  hover:text-white xl:rounded-md tracking-wide  mx-auto md:mx-0 flex justify-center items-center cursor-pointer '
              >
                Home
              </button>
            </div> */}
          </div>
        )}
        {/* previous sermon */}
        <section className='section-center'>
          {/* title */}
          <div className='flex flex-col justify-center items-center space-y-2 '>
            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='200'
              className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
            >
              Previous <span className='text-babypurple'> Sermon </span>
            </h1>
            {/* top */}
            <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
              Here’s a recap of some of our past power packed sermons
            </h2>
          </div>
          <div className='grid grid-cols-1 gap-10 mt-[3em] sm:grid-cols-2 lg:grid-cols-3 md:mt-[6em] dstream'>
            {videos
              .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
              .map((item, index) => (
                <div key={index} className=''>
                  <p className='text-sm mb-2 font-semibold'>{item?.title}</p>
                  <div
                    className='bg-gray-200'
                    style={{ width: '100%', height: '250px' }}
                  >
                    <ReactPlayer
                      width={'100%'}
                      height={'100%'}
                      url={item?.video_url}
                    />
                  </div>
                  <div className='flex mt-4 justify-between items-center'>
                    <div className='flex items-center'>
                      <img src={User} alt='pastor' />
                      <p className='text-[9px] sm:text-xs mb-0 font-medium ml-2'>
                        {item?.preacher}
                      </p>
                    </div>
                    <div className='flex items-center'>
                      <img src={Calendar} alt='Calendar' />
                      <p className='text-[9px] sm:text-xs mb-0 font-medium ml-2'>
                        {fDate(item?.createdAt)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default Live
