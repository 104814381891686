import { Modal } from 'antd'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { DonateLogo, GiveArrow } from '../asset'
import { GiReceiveMoney, GiTakeMyMoney } from 'react-icons/gi'
import { MdOutlinePayments } from 'react-icons/md'
import AOS from 'aos'
import 'aos/dist/aos.css'
import PaymentModal from '../components/PaymentModal'
import { BiBible } from 'react-icons/bi'
import { GiStumpRegrowth } from 'react-icons/gi'
import { RiTeamLine } from 'react-icons/ri'
import { FaPersonBooth } from 'react-icons/fa'
import { RiBaseStationLine } from 'react-icons/ri'
import { BsMailbox2 } from 'react-icons/bs'

const Donate = () => {
  const [open, setOpen] = useState(false)
  const [isPaid, setIsPaid] = useState(false)
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <>
      {open && (
        <PaymentModal opening={open} closing={setOpen} setIsPaid={setIsPaid} />
      )}
      <Modal
        bodyStyle={{
          padding: 0,
          borderRadius: '10px',
        }}
        centered
        footer={null}
        title={null}
        visible={isPaid}
        onOk={() => setIsPaid(false)}
        onCancel={() => setIsPaid(false)}
      >
        <div className='bg-white rounded-[10px] shadow py-[5rem] px-[2rem] w-full'>
          <h2 className='text-center capitalize font-bold text-[#A303A0] text-xl md:text-2xl'>
            We appreciate your benevolence
          </h2>
          <p className='text-center text-sm md:text-xl font-medium'>
            God bless and reward your generousity
          </p>
        </div>
      </Modal>
      <Navbar />
      <section className='mt-10 mb-16 xl:mt-6 xl:mb-24   max-w-xs sm:max-w-md mx-auto font-sans md:max-w-4xl lg:max-w-5xl xl:max-w-7xl  px-4 md:px-6  lg:px-8 '>
        <div className='flex flex-col justify-center items-center space-y-10 md:space-y-0 md:flex-row md:justify-between md:gap-6'>
          {/* text */}
          <div className='md:w-1/2 md:space-y-6 lg:space-y-8'>
            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='400'
              className='text-2xl sm:text-3xl font-bold text-center md:text-left md:text lg:text-4xl lg:max-w-sm xl:text-5xl xl:max-w-md 2xl:text-6xl 2xl:max-w-lg font-mono sm:leading-[2.8rem] lg:leading-[3rem] xl:leading-[4rem]'
            >
              It is more <span className='text-[#A303A0]'>Blessed</span> to{' '}
              <span className='text-[#A303A0]'>Give</span> than to recieve
            </h1>
            <p className='text-center sm:text-base md:text-left md:text-sm lg:text-lg lg:max-w-sm xl:text-xl 2xl:text-xl xl:max-w-md '>
              We welcome you to support the mission of spreading the gospel by
              giving cheerfully as God love a cheerful giver. May the good lord
              bless you as you support our ministry. Amen
            </p>
            <button
              data-aos='fade-down'
              data-aos-duration='2000'
              data-aos-delay='500'
              onClick={() => setOpen(true)}
              className='hover:bg-veryLightGray  px-6 py-1 md:px-6 md:py-1 xl:px-12  2xl:px-16 lg:px-8 xl:py-3 md:text-lg font-bold  text-white rounded-sm bg-[#a303a0] hover:text-white xl:rounded-md duration-1000 cursor-pointer flex justify-center items-center mx-auto md:mx-0'
              type='default  '
            >
              Give
            </button>
          </div>
          {/* imgage */}
          <div
            data-aos='fade-left'
            data-aos-duration='2000'
            data-aos-delay='500'
            className=' border-b-2 border-[#A303A0] md:w-1/2'
          >
            <img
              src={DonateLogo}
              alt=''
              className='md:max-w-xs lg:max-w-sm xl:max-w-md '
            />
          </div>
        </div>
      </section>
      {/* Why we give */}
      <section className='section-center space-y-12 xl:space-y-14 2xl:space-y-16'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Why we <span className='text-babypurple'> Give</span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Outlined below are reasons why we sow in church
          </h2>
        </div>
        {/* values */}
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='600'
          className=' space-y-8 flex flex-col justify-center items-center md:flex-row md:space-y-0 md:justify-between md:gap-4 xl:gap-6 2xl:gap-12'
        >
          {/* content 1 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-72 md:w-1/3 xl:h-72'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <BiBible className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>
              Obidence
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              Buy giving cheerfully to support God’s ministry, we are acting in
              obedience to Gods word in the book of 2 Corinthians 9:6-8 and we
              in turn expect to receive his blessings as he has promised
            </p>
          </div>
          {/* content 2 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-72 md:w-1/3 xl:h-72'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md texxt-white'>
              <GiStumpRegrowth className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>
              Prosperity
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              As we acknowledge God with our gifts and offering, we open doors
              for more blessings and prosperity because God is just and we give
              back to each of us according to the measure we have given
            </p>
          </div>
          {/* content 3 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-72 xl:h-72 md:w-1/3'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <RiTeamLine className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>
              Partnership
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              Our seeds and offerings go a long way to foster the spread of the
              good news to millions of people all over the world who need to
              hear about Jesus.
            </p>
          </div>
        </div>
      </section>
      {/* what we give */}
      <section className='section-center'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            What we <span className='text-babypurple'> Give</span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Giving to the church can come in these forms
          </h2>
        </div>
        {/* content */}
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className='mx-auto mt-[4rem] flex flex-col justify-center space-y-12 md:grid md:grid-cols-2 xl:grid-cols-3 md:space-y-0  md:gap-x-4 md:gap-y-8 lg:gap-x-6 lg:gap-y-10 w-full xl:gap-x-8 xl:gap-y-14'
        >
          {/* cont1 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babypurple rounded-full flex justify-center items-cente '>
              <GiTakeMyMoney className=' text-white  text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>Our Offering</h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                Our offerings are seeds we sow to acknowledge God for his
                goodness in our lives. These offerings can come in varous
                mediums some of which include but not limited to cash offering,
                material offering, food offering, e.t.c.
              </h5>
            </div>
          </div>
          {/* cont2 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babypurple rounded-full flex justify-center items-center '>
              <GiReceiveMoney className=' text-white  text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>Our Tithes</h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                Tithes are one percent of our income. We are mandated by the
                bible in the book of Malachi 3:16 to set aside this portion of
                our income for the lord .As we do this may be windows of heaven
                be opened to us in Jesus name. Amen.
              </h5>
            </div>
          </div>
          {/* cont3 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babypurple rounded-full flex justify-center items-center  '>
              <MdOutlinePayments className=' text-white text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>Our Seeds</h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                Seeds are special offerings rendered to the lord as a sign of
                appreciation for his good deeds. Seeds are given voluntarily as
                seem fit by an individual. We welcome you to sow good seeds to
                the lord as a sign of appreciation or in request for a
                particular favour.
              </h5>
            </div>
          </div>
        </div>
      </section>

      {/* how to give */}
      <section className='section-center space-y-16'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            How to <span className='text-babypurple'> Give</span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            You can sow your seeds through these mediums
          </h2>
        </div>
        {/* values */}
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className=' space-y-8 flex flex-col justify-center items-center md:flex-row md:space-y-0 md:justify-between md:gap-4 xl:gap-6 2xl:gap-12'
        >
          {/* content 1 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-80 md:w-1/3 xl:h-72'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <FaPersonBooth className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>
              In-Person
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              You are welcomed to personally present your donations to us at our
              church location, 113 Muenster Dr. Hutto TX 78634. Our
              administrative staff will be available to receive you.
            </p>
          </div>
          {/* content 2 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-80 md:w-1/3 xl:h-72'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md texxt-white'>
              <RiBaseStationLine className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>Online</h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              Online payments mediums have been known to be the fast and most
              reliable way of give. We indulge you to make your donations online
              through the link below as our site payment system is safe and
              secure.
            </p>
            <Link
              to='#'
              onClick={() => setOpen(true)}
              className='mt-1 text-[#A303A0]  flex items-center text-bold underline'
            >
              <img
                style={{
                  width: '30px',
                  height: '10px',
                  objectFit: 'cover',
                  marginRight: '10px',
                }}
                src={GiveArrow}
                alt='arrow'
              />
              GIVE NOW
            </Link>
          </div>
          {/* content 3 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-80 xl:h-72 md:w-1/3'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <BsMailbox2 className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>Mail</h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              You send us a cashier cheque addressed in the name of the church
              to the church. Thank you
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Donate
