import React from 'react'
import { BsFacebook } from 'react-icons/bs'
import { FaInstagramSquare } from 'react-icons/fa'
import { AiFillYoutube } from 'react-icons/ai'
import { logo } from '../asset'
import { Link } from 'react-router-dom'
function Footer() {
  return (
    <div className=' space-y-12 md:space-y-16 xl:space-y-20 py-10 bg-grayishBlue'>
      {/* container */}
      <div className='px-4 md:px-6  lg:px-8 flex flex-col lg:flex-row justify-between  mx-auto space-y-10 lg:space-y-0 font-sans text-white'>
        {/* logo */}
        <div className='relative flex flex-col justify-center items-center mx-auto space-y-3 lg:hidden'>
          <img
            src={logo}
            alt='logo'
            width={1000}
            height={1000}
            className='object-cover w-24  '
          />
          <p className='font-black text-3xl text-center  text-white'>
            Glorious Nation
          </p>
        </div>
        {/* group */}
        <div className='grid grid-cols-2 gap-x-14  sm:gap-x-24 gap-y-12 md:gap-x-24 md:gap-y-12 lg:gap-x-16 xl:gap-x-20 mx-auto lg:grid-cols-5 '>
          <div className='relative hidden flex-col justify-center items-center mx-auto space-y-3 lg:block'>
            <img
              src={logo}
              alt='logo'
              width={1000}
              height={1000}
              className='object-cover w-24  '
            />
            <p className='font-black text-3xl text-left  text-white'>
              Glorious Nation
            </p>
          </div>
          {/* Quick links */}
          <div className=' space-y-4  '>
            <h1 className='font-bold font-babyblack text-sm lg:text-lg text-white'>
              Quick Links
            </h1>
            {/* hours */}
            <div className='space-y-2'>
              <p className='text-whitek text-xs lg:text-sm max-w-xs cur'>
                <Link to='/about' className='text-white'>
                  About Us
                </Link>
              </p>

              <p className='text-babyblack text-xs lg:text-sm max-w-xs'>
                <Link to='/connect' className='text-white'>
                  Contact Us
                </Link>
              </p>
              <p className='text-babyblack text-xs lg:text-sm max-w-xs'>
                <Link to='/doualacenter' className='text-white'>
                  Douala Center
                </Link>
              </p>
              <p className='text-babyblack text-xs lg:text-sm max-w-xs'>
                <Link to='/foundation' className='text-white'>
                  Our Foundation
                </Link>
              </p>
              <p className='text-babyblack text-xs lg:text-sm max-w-xs'>
                <Link to='/donate' className='text-white'>
                  Give
                </Link>
              </p>
            </div>
          </div>
          {/* office address */}
          <div className='  space-y-4 '>
            <h1 className=' font-bold font-babyblack text-sm lg:text-lg text-white'>
              Church Address
            </h1>
            {/* hours */}
            <div className='space-y-2'>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                10205 North Lamar Blvd,
              </p>
              <p className='text-whitetext-xs lg:text-sm max-w-xs'>
                Suite 113 Austin TX 78753,
              </p>
              <p className='text-white text-sm max-w-xs'>USA</p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                gngloriousnation@gmail.com
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                +15129953189
              </p>
            </div>
          </div>
          {/* contact Information */}
          <div className=' space-y-4 '>
            <h1 className='font-bold font-babyblack text-sm lg:text-lg text-white'>
              Douala Address
            </h1>
            {/* hours */}
            <div className='space-y-2'>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                Glorious Nation Church Douala
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                Bonaberi behind fokou
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                opposite MTN antenna
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                +237680561172
              </p>
            </div>
          </div>
          {/* social media Information */}
          <div className=' space-y-4 '>
            <h1 className='font-bold font-babyblack text-sm lg:text-lg text-white'>
              Social Media
            </h1>
            {/* hours */}
            <div className='flex items-center gap-4  '>
              {/* facebook */}
              <a
                href='https://www.facebook.com/Christthehopeglory/'
                rel='noreferrer'
                target='_blank'
                className='block'
              >
                <BsFacebook className='text-white text-xl' />
              </a>

              {/* instagram */}
              <a
                href='https://instagram.com/franklin_tebid?igshid=YmMyMTA2M2Y='
                rel='noreferrer'
                target='_blank'
                className='block'
              >
                <FaInstagramSquare className='text-white text-xl' />
              </a>

              {/* youtube */}
              <a
                href='https://youtube.com/channel/UC3PsOBG9ptHzb3xenIfwHqQ'
                rel='noreferrer'
                target='_blank'
                className='block'
              >
                <AiFillYoutube className='text-white text-xl' />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* divider */}
      {/* <div className='bg-babyblue h-[0.5px] w-full'></div> */}
      {/* date */}
      <div className='text-center text-white font-sans max-w-xs md:max-w-full flex flex-col justify-center mx-auto space-y-2 '>
        <p className='text-xs font-bold  lg:text-sm xl:text-base tracking-wide'>
          Let's embark on a transformative journey of love, hope, and spiritual
          growth.
        </p>
        <p className='text-xs'>
          © 2021 - {new Date().getFullYear()} Glorious Nation Church
        </p>
      </div>
    </div>
  )
}

export default Footer
