import React, {createContext, useState} from 'react'

export const eventsContext = createContext()

export default function EventsContextProvider (props){
    const [events, setEvents] = useState([])
    const data = [events, setEvents]

    return (
        <eventsContext.Provider value={data}>
            {props.children}
        </eventsContext.Provider>
      )
}

