import axios from "axios"

export const GetTestimonies = ()=>{
    return async(successCallback, errorCallback)=>{
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_URL}testimonies/approve`)
            successCallback?.(data)
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
        }
    }
}
export const GetBlogs = ()=>{
    return async(successCallback, errorCallback)=>{
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_URL}blog`)
            successCallback?.(data)
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
        }
    }
}
export const GetProphecy = ()=>{
    return async(successCallback, errorCallback)=>{
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_URL}prophecy`)
            successCallback?.(data)
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
        }
    }
}
export const GetFlyer = ()=>{
    return async(successCallback, errorCallback)=>{
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_URL}flyer`)
            successCallback?.(data)
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
        }
    }
}
export const GetVideos = ()=>{
    return async(successCallback, errorCallback)=>{
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_URL}videos`)
            successCallback?.(data)
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
        }
    }
}
export const GetEvents = ()=>{
    return async(successCallback, errorCallback)=>{
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_URL}events`)
            successCallback?.(data)
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
        }
    }
}
export const GetLive = ()=>{
    return async(successCallback, errorCallback)=>{
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_URL}live`)
            successCallback?.(data)
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
        }
    }
}


export const CreateEditTestimonies = ()=>{
    return async(datas, successCallback, errorCallback)=>{
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_URL}testimonies`, datas)
            console.log(data)
            successCallback?.(data)
            // showToast(data.message ?? "Done", "success")
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
            // showToast(error.response.message ?? "Error Occured, Try again", "error")
        }
    }
}
export const CreateRequest = ()=>{
    return async(datas, successCallback, errorCallback)=>{
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_URL}request`, datas)
            console.log(data)
            successCallback?.(data)
            // showToast(data.message ?? "Done", "success")
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
            // showToast(error.response.message ?? "Error Occured, Try again", "error")
        }
    }
}
export const CreateTransaction = ()=>{
    return async(datas, successCallback, errorCallback)=>{
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_URL}transaction`, datas)
            console.log(data)
            successCallback?.(data)
            // showToast(data.message ?? "Done", "success")
        } catch (error) {
            console.log(error.response)
            errorCallback?.()
            // showToast(error.response.message ?? "Error Occured, Try again", "error")
        }
    }
}