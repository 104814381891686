import React, { useState, useEffect } from 'react'
import { CreateRequest } from '../api/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import p1 from '../assets/p1.jpeg'
import p2 from '../assets/p2.jpeg'
import p3 from '../assets/p3.jpeg'
import p4 from '../assets/p4.jpeg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Diversity from '../components/Diversity'
import { og1, og2, og3, og4, og5, og6, past } from '../asset'
import CarouselContainer from '../components/Carousel'
import { SiTripadvisor } from 'react-icons/si'
import { FaBible } from 'react-icons/fa'
import { BiTargetLock } from 'react-icons/bi'

const About = () => {
  const s = useNavigate()
  const [isShare, setIsShare] = useState(true)
  const [isLink, setIsLink] = useState(true)
  const [loading, setLoading] = useState(false)
  const createRequest = CreateRequest()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')

  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)

  useEffect(() => {
    AOS.init()
  }, [])
  const notify = () =>
    toast.success(`Request Submitted Successfully`, {
      position: 'top-center',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })

  const handleClick = () => {
    setNameError(false)
    setEmailError(false)

    // if (name === '') {
    //   return setNameError(true)
    // }
    if (email === '') {
      return setEmailError(true)
    }
    const payload = {
      firstname: ' N / A',
      lastname: 'N / A',
      section: 'newsletter',
      email,
      country: 'N/A',
      content: 'N/A',
    }
    createRequest(
      payload,
      () => {
        notify()
        setLoading(false)
        setEmail('')
        setName('')
      },
      () => {
        setLoading(false)
      }
    )
  }
  return (
    <>
      {/* hero */}
      <div className='backdd-image   h-[26rem] md:h-[34rem]  lg:h-[38rem] xl:h-[100vh] flex flex-col justify-center pt-[4rem] md:pt-[6rem] items-center text-white space-y-2 sm:space-y-4  md:space-y-8 px-2 pb-6 2xl:pb-14'>
        <div className='absolute top-0 left-0 right-0 z-20'>
          <Navbar />
        </div>
        <div>
          <h1
            data-aos='fade-down'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-3xl font-mono max-w-xs text-center sm:max-w-sm sm:text-4xl md:text-5xl md:max-w-lg lg:text-5xl xl:text-6xl lg:max-w-3xl  tracking-wide text-white mx-auto '
          >
            Glorious Nation
          </h1>
          <p
            data-aos='fade-down'
            data-aos-duration='2000'
            data-aos-delay='600'
            className='font-sans text-white font-normal text-center max-w-xs sm:max-w-md md:text-lg md:max-w-xl lg:text-xl lg:max-w-2xl xl:text-2xl xl:max-w-4xl'
          >
            We are an embodiment of empowerment of the Work of God, the
            authority and power Jesus has given us as sons of God and the
            fellowship of the Holy Spirit who brings every word into
            manifestation in our lives. You are welcome to be part of us and
            discover more about us below. Free feel to hit the connect button
            below to get in touch with us
          </p>
        </div>

        {/* call to action */}
        <div className='flex justify-center items-center gap-6'>
          <button
            onClick={() => s('/connect')}
            className='bg-white px-4 py-2 md:px-4 md:py-2 lg:px-6 xl:px-7 lg:py-3 xl:py-4   font-bold  text-babypurple shadow shadow-white  rounded-sm  hover:bg-babypurple duration-1000  hover:text-white xl:rounded-md tracking-wide  mx-auto md:mx-0 flex justify-center items-center cursor-pointer '
          >
            Connect with Us
          </button>
        </div>
      </div>
      {/* core values */}
      <section className='section-center space-y-12 xl:space-y-14 2xl:space-y-16'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Our Core <span className='text-babypurple'> Values </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            These are the values that guide our path
          </h2>
        </div>

        {/* values */}
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className=' space-y-8 flex flex-col justify-center items-center md:flex-row md:space-y-0 md:justify-between md:gap-4 xl:gap-6 2xl:gap-12'
        >
          {/* content 1 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-80 md:w-1/3  xl:h-72'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <SiTripadvisor className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>
              Our Vision
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              To raise fearless men and women of God all over the Globe who are
              empowered by the Holy Spirit with the Sole purpose of depopulating
              the kingdom of darkness and populating the Kingdom of Heaven by
              Preaching the undiluted word of God through.{' '}
            </p>
          </div>
          {/* content 2 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-80 md:w-1/3  xl:h-72 '>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <BiTargetLock className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>
              Our Mission
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              To Take the Good News of Jesus Christ to the Ends of the Earth by
              preaching, teaching and Exposing this Generation to the infinite
              Love of God in Christ Jesus through Fellowship with the Holy
              Spirit. We Recuperate, Redress and Endow.
            </p>
          </div>
          {/* content 3 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-80 xl:h-72 md:w-1/3'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <FaBible className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl font-sans font-bold xl:text-2xl'>
              Our Belief
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              {/* And those he predestined, he also called; those he called, he also
              justified; those he justified, he also glorified. (Romans 8:30). */}
              We believe, those who are sincere in seeking the Lord diligently
              will live in abundance and fullness of the Lord. Your commitment
              will determine how much of God’s Glory you will experience in your
              life on earth.
            </p>
          </div>
        </div>
      </section>
      {/* guardian */}
      <section className='section-center space-y-6 xl:space-y-10 2xl:space-y-12'>
        {/* cont */}
        <div className='mt-16 md:mt-10 lg:mt-16 space-y-8 sm:space-y-12 md:space-y-0 md:flex items-center justify-between gap-6 xl:gap-14'>
          {/* image */}
          <div
            data-aos='fade-right'
            data-aos-duration='2000'
            data-aos-delay='400'
            className='flex justify-center items-center md:w-1/2 '
          >
            <img
              src={past}
              alt=''
              className='rounded-lg w-54 sm:w-full sm:max-w-xs md:max-w-full  '
            />
          </div>
          {/* text */}
          <div className='md:w-1/2 space-y-4 md:space-y-5 xl:space-y-6'>
            <div>
              <h1
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='200'
                className='text-babyblack text-xl font-bold relative w-max sm:text-2xl md:text-xl lg:text-2xl mx-auto  xl:text-3xl text-center md:text-left md:mx-0'
              >
                Our
                <span className='text-[#A303A0]'> Guardians </span>
              </h1>
              <h1 className='text-center text-base text-babyblack  font-bold sm:text-xl  md:text-left xl:text-3xl md:max-w-xs xl:max-w-full font-mono tracking-wide'>
                Apostle Franklin & Pastor Mirabel Tebid
              </h1>
            </div>

            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='500'
              className='text-justify sm:text-sm  sm:max-w-md md:text-xs md:text-left md:max-w-xl lg:text-sm lg:max-w-lg  xl:text-base xl:max-w-xl  2xl:text-base'
            >
              Franklin Tebid is an Apostle of the Word Minister of the Gospel, a
              preacher, and a Missionary to those in Need. He is Married to his
              wife Pastor Mirabel Tebid-Fonji an Evangelist and Counselor to
              families. They currently live in Austin Texas. The two are blessed
              with 5 kids; Favor Ruth Teke Franklin, Elijah Promise Teke
              Franklin, Anna Grace-Destiny Teke Franklin, Ezekiel Awesome Teke
              Franklin and Samuel-EL Goodheart Teke Franklin. Through in-house
              meetings,Facebook and Youtube, Franklin and Mirabel have been
              spreading the Gospel all over the world and are touching the lives
              of many. Glorious Nation is open to Partner with Spirit filled
              Pastors both men and Women from different nations of the world who
              will spread the word across the globe under the umbrella of
              Glorious Nation Mission and Vision. Franklin & Mirabel are both
              from Central Africa Cameroon but Migrated to the United States
              where they received and acknowledged the call of God in their
              lives. God brought this two together solely for his mission which
              is Kingdom Business. These two are so passionate about winning
              souls for the kingdom of God by taking the love of God to the lost
              sheep, manifesting the power of God through Healing, Deliverance
              and Miracles, the prophetic and teaching God’s Children to live in
              Fulfillment and Abundance in every area of their lives including
              their Families, finances, Careers, Businesses and Marriages etc.
              Glorious Nation is all about God’s kingdom Business.
            </h1>
          </div>
        </div>
      </section>
      {/* pastorial team */}
      <section className='section-center'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Our <span className='text-babypurple'> Pastoral Team </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Meet our team of highly dedicated Ministers
          </h2>
        </div>

        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className='mt-[4rem] w-full'
        >
          <CarouselContainer maxSize={3}>
            {/* one */}
            <div className='bg-white shadow-md  px-4 py-4 space-y-6 rounded-md max-w-xs    lg:max-w-[17rem]  border border-babypurple  '>
              {/* image */}
              <div className=' flex justify-center items-center mx-auto max-w-xs rounded-md'>
                <img
                  className='w-full h-52 md:h-60  object-cover  object-top rounded-md '
                  src={p1}
                  alt='pastor'
                />
              </div>
              {/* text */}
              <div className='text-center space-y-1'>
                <p className='text-[#A303A0] mb-0 font-semibold text-base'>
                  Franklin Tebid (PhD)
                </p>
                <p className='text-babyblack mb-0  text-xs'>Apostle</p>
              </div>
            </div>
            {/* one */}
            <div className='bg-white shadow-md  px-4 py-4 space-y-6 rounded-md max-w-xs  lg:max-w-[17rem]    border border-babypurple  '>
              {/* image */}
              <div className=' flex justify-center items-center mx-auto'>
                <img
                  className='w-full rounded-md  h-52 md:h-60   object-cover  object-top '
                  src={p2}
                  alt='pastor'
                />
              </div>
              {/* text */}
              <div className='text-center space-y-1'>
                <p className='text-[#A303A0] mb-0 font-semibold text-base'>
                  Mirabel Tebid (PhD)
                </p>
                <p className='text-babyblack mb-0  text-xs'>Pastor</p>
              </div>
            </div>
            {/* one */}
            <div className='bg-white shadow-md  px-4 py-4 space-y-6 rounded-md max-w-xs lg:max-w-[17rem]   border border-babypurple '>
              {/* image */}
              <div className=' flex justify-center items-center mx-auto'>
                <img
                  className='w-full rounded-md h-52  md:h-60  object-cover object-top '
                  src={p3}
                  alt='pastor'
                />
              </div>
              {/* text */}
              <div className='text-center space-y-1'>
                <p className='text-[#A303A0] mb-0 font-semibold text-base'>
                  Kingsley Tikum
                </p>
                <p className='text-babyblack mb-0  text-xs'>
                  Resident Pastor Douala
                </p>
              </div>
            </div>
            {/* one */}
            <div className='bg-white shadow-md  px-4 py-4 space-y-6 rounded-md max-w-xs   lg:max-w-[17rem]   border border-babypurple '>
              {/* image */}
              <div className=' flex justify-center items-center mx-auto'>
                <img
                  className='w-full rounded-md h-52  md:h-60  object-cover object-top '
                  src={p4}
                  alt='pastor'
                />
              </div>
              {/* text */}
              <div className='text-center space-y-1'>
                <p className='text-[#A303A0] mb-0 font-semibold text-base'>
                  Glory Tikum
                </p>
                <p className='text-babyblack mb-0  text-xs'>
                  Resident Pastor Douala
                </p>
              </div>
            </div>
          </CarouselContainer>
        </div>
      </section>
      {/* gallery */}
      <section className='section-center space-y-16 xl:space-y-20 2xl:space-y-24'>
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Our <span className='text-babypurple'> Gallery </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Familiarize yourself with images from our church sessions
          </h2>
        </div>
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className='space-y-8 flex flex-col justify-center items-center md:space-y-0 md:gap-6 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:gap-10 mx-auto'
        >
          {/* image 1 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm hover:shadow-veryLightGray   duration-1000'>
            <img src={og1} alt='' className='' />
          </div>
          {/* image 2 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm    duration-1000'>
            <img src={og2} alt='' className='     object-center' />
          </div>
          {/* image 3 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center hover:shadow-sm duration-1000'>
            <img src={og3} alt='' className='' />
          </div>
          {/* image 4 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm duration-1000'>
            <img src={og4} alt='' className='' />
          </div>
          {/* image 5 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm   '>
            <img src={og5} alt='' className='' />
          </div>
          {/* image6 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm '>
            <img src={og6} alt='' className='' />
          </div>
        </div>
      </section>
      {/* news letter */}
      <section className='my-16 md:my-28 xl:my-32 max-w-xs sm:max-w-md mx-auto font-sans md:max-w-2xl lg:max-w-3xl xl:max-w-5xl  px-4 md:px-6  lg:px-8'>
        <div className='space-y-10 md:flex justify-center items-center md:gap-4 lg:gap-6 md:space-y-0 md:mx-auto md:bg-white md:shadow-lg'>
          {/* photo */}
          <div className=' w-full md:w-1/2    '>
            <Diversity />
          </div>
          {/* text */}
          <div className='space-y-6 md:space-y-2 xl:space-y-8 md:w-1/2 sm:max-w-xs lg:max-w-sm xl:max-w-sm flex flex-col justify-center items-center mx-auto md:items-start'>
            {/* heading */}
            <div className='space-y-1 md:space-y-2 xl:space-y-3'>
              {/* top */}
              {/* headline */}
              <div>
                <h1
                  data-aos='fade-up'
                  data-aos-duration='2000'
                  data-aos-delay='500'
                  className='text-babyblack font-bold relative w-max sm:text-2xl md:text-xl lg:text-2xl mx-auto  xl:text-3xl text-center md:text-left md:mx-0'
                >
                  Our
                  <span className='text-babypurple'> Newsletter </span>
                </h1>
                <h1 className='text-center text-babyblack  font-bold sm:text-xl md:text-lg  md:text-left xl:text-3xl md:max-w-xs xl:max-w-md font-mono'>
                  Stay up to date on our church activities
                </h1>
              </div>
              {/* bottom */}
            </div>
            {/* others */}
            <div className='space-y-3 md:space-y-5  lg:space-y-6 xl:space-y-10'>
              <p
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='600'
                className='text-xs sm:text-sm md:text-xs text-center  md:text-left lg:text-base xl:text-base text-babyblack md:pr-4 xl:pr-6'
              >
                Never miss out on what's new and trending in our community. Sign
                up for our newsletter today so you never have to miss out. We
                promise not to spam you.
              </p>
              <div className=' '>
                <div className='flex flex-col md:flex-row justify-center items-center md:items-start md:-space-x-2 md:pr-4'>
                  <input
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email...'
                    className={`${
                      emailError ? 'border-3 border-red-600' : ''
                    } border outline-0  border-babypurple footer_inputs mb-4 h-[2.5rem] lg:h-12 xl:h-14 rounded-md px-5 max-w-xs  md:w-2/3 md:border-r-0`}
                  />
                  <button
                    disabled={loading}
                    onClick={() => handleClick()}
                    className='bg-babypurple rounded-md px-6 py-2 font-bold text-white md:w-1/3 h-[2.5rem] lg:h-12 xl:h-14 md:text-xs'
                    type='button'
                  >
                    {loading ? 'Loading..' : 'Sign Up'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default About
