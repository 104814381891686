import React, {createContext, useState} from 'react'

export const videosContest = createContext()

export default function VideosContestProvider (props){
    const [videos, setVideos] = useState([])
    const data = [videos, setVideos]

    return (
        <videosContest.Provider value={data}>
            {props.children}
        </videosContest.Provider>
      )
}

