/* eslint-disable react/prop-types */
import React, {createContext, useState} from 'react'

export const prophecyContest = createContext()

export default function ProphecyContestProvider (props){
    const [prophecy, setProphecy] = useState([])
    const data = [prophecy, setProphecy]

    return (
        <prophecyContest.Provider value={data}>
            {props.children}
        </prophecyContest.Provider>
      )
}

