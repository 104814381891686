import './App.css';
import Screen from './Screen';
import {BrowserRouter as Router} from 'react-router-dom'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import BlogContestProvider from './context/blog';
import TestimonyContestProvider from './context/testimonies';
import FlyerContextProvider from './context/flyer';
import ProphecyContestProvider from './context/prophecy';
import VideosContestProvider from './context/videos';
import EventsContextProvider from './context/event';

function App() {
  const initialOptions = {
    "client-id": process.env.REACT_APP_CLIENT_ID,
    currency: "USD",
    intent: "capture",
};
  return (
    <PayPalScriptProvider options={initialOptions}>
      <BlogContestProvider>
        <TestimonyContestProvider>
          <FlyerContextProvider>
            <ProphecyContestProvider>
              <VideosContestProvider>
                <EventsContextProvider>
                  <Router>
                    <Screen />
                  </Router>
                </EventsContextProvider>
              </VideosContestProvider>
            </ProphecyContestProvider>
          </FlyerContextProvider>
        </TestimonyContestProvider>
      </BlogContestProvider>
    </PayPalScriptProvider>
  );
}

export default App;
