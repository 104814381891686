import React, {createContext, useState} from 'react'

export const blogContest = createContext()

export default function BlogContestProvider (props){
    const [blogs, setBlogs] = useState([])
    const data = [blogs, setBlogs]

    return (
        <blogContest.Provider value={data}>
            {props.children}
        </blogContest.Provider>
      )
}

