import { Button, Input, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect } from '../asset'
import { useFormik } from 'formik'
import * as yup from 'yup'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Footer from '../components/Footer'
import { CreateEditTestimonies, CreateRequest } from '../api/api'
import { toast } from 'react-toastify'
import Navbar from '../components/Navbar'
const Connect = () => {
  const { Option } = Select
  const [loading, setLoading] = useState(false)
  const createEditTestimonies = CreateEditTestimonies()
  const createRequest = CreateRequest()
  const [mainSection, setMainSection] = useState('Testimony')
  const initialValues = {
    firstname: '',
    lastname: '',
    content: '',
    title: '',
    action: 'c',
    section: mainSection,
    email: '',
    date_of_visit: '',
    phone: '',
    country: '',
    isTestimony: true,
  }

  const TestimonySchema = yup.object({
    firstname: yup.string().required('Kindly include a name'),
    isTestimony: yup.boolean(),
    title: yup.string().when('isTestimony', {
      is: true,
      then: yup.string().required('Kindly add a title'),
    }),
    country: yup.string().required('Kindly add a location'),
    content: yup
      .string()
      .required('Kindly include a breif content message')
      .max(750, 'Maximum Content Characters is 750'),
  })

  const resetForm = () => {
    setFieldValue('firstname', '')
    setFieldValue('lastname', '')
    setFieldValue('content', '')
    setFieldValue('title', '')
    setFieldValue('email', '')
    setFieldValue('country', '')
    setFieldValue('action', 'c')
    setFieldValue('date_of_visit', '')
    setFieldValue('phone', '')
  }
  const filterDatas = (obj) => {
    for (let value in obj) {
      if (obj[value] === '') {
        delete obj[value]
      }
    }
    return obj
  }
  const notify = () =>
    toast.success(`Request Submitted Successfully`, {
      position: 'top-center',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })

  const handleSubmit = async (data) => {
    const {
      firstname,
      lastname,
      title,
      country,
      content,
      action,
      section,
      date_of_visit,
      phone,
    } = data
    if (section === 'Testimony') {
      const sender_name = firstname + ' ' + lastname
      const payload = {
        sender_name,
        title,
        content,
        action,
        country,
        phone,
        date_of_visit,
      }
      setLoading(true)
      // createEditTestimonies()
      createEditTestimonies(
        payload,
        () => {
          notify()
          setLoading(false)
          resetForm()
        },
        () => {
          setLoading(false)
        }
      )
      return
    }
    const newD = filterDatas(data)
    delete newD.isTestimony
    delete newD.action
    console.log(newD)
    setLoading(true)
    createRequest(
      newD,
      () => {
        notify()
        setLoading(false)
        resetForm()
      },
      () => setLoading(false)
    )
  }
  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: TestimonySchema,
  })

  useEffect(() => {
    setFieldValue('section', mainSection)
  }, [mainSection])

  const handleClick = (tag) => {
    if (tag === 'Testimony') {
      setFieldValue('isTestimony', true)
      setMainSection(tag)
    } else {
      setFieldValue('isTestimony', false)
      setMainSection(tag)
    }
  }

  useEffect(() => {
    AOS.init()
  }, [])

  const { setFieldValue, values, errors } = formik
  return (
    <>
      <Navbar />
      {/* hero */}
      <section className='mb-16 xl:mb-40 max-w-xs sm:max-w-md mx-auto font-sans md:max-w-4xl lg:max-w-5xl xl:max-w-7xl  px-4 md:px-6  lg:px-8 '>
        <div className='flex flex-col justify-center items-center mt-10  space-y-10 md:space-y-0 md:flex-row md:justify-between md:gap-6'>
          {/* text */}
          <div className='md:w-1/2 md:space-y-6 lg:space-y-8'>
            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='200'
              className='text-4xl sm:text-4xl font-bold text-center md:text-left md:text lg:text-5xl lg:max-w-sm xl:text-6xl xl:max-w-md 2xl:text-6xl 2xl:max-w-lg font-mono '
            >
              Connect with
              <span className='text-[#A303A0]'> US</span> Today !
            </h1>
            <p className='text-center max-w-xs sm:text-base md:text-left md:text-sm lg:text-lg lg:max-w-sm xl:text-xl 2xl:text-xl xl:max-w-lg '>
              At Glorious Nation, we care about the physical and Spiritual needs
              of every child of God. Do you have any inquires, testimony, prayer
              request or any information you would like to share with us? Then
              we welcome you to fill the form below stating clearly the subject
              of your inquiry and we would get back to you in little or no time.
            </p>
          </div>
          {/* imgage */}
          <div
            data-aos='fade-left'
            data-aos-duration='2000'
            data-aos-delay='400'
            className='  md:w-1/2'
          >
            <img
              src={connect}
              alt=''
              className='md:max-w-xs lg:max-w-sm xl:max-w-md '
            />
          </div>
        </div>
      </section>
      {/* form */}
      <section className='section-center'>
        <div className='container mx-auto'>
          <div className=' '>
            <form
              onSubmit={formik.handleSubmit}
              className='w-full px-0 md:w-3/4 mb-0 md:mb-[2rem] mx-auto sm:px-10 '
            >
              <div className='flex my-4 flex-col sm:flex-row justify-between'>
                <div className='w-full mb-[2rem] sm:mb-0 sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='firstname'>
                    First Name
                  </label>
                  <Input
                    className='inputs'
                    name='firstname'
                    onChange={(e) => setFieldValue('firstname', e.target.value)}
                    value={values.firstname}
                    placeholder='First Name'
                  />
                </div>
                <div className='w-full sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='last_name'>
                    Last Name
                  </label>
                  <Input
                    className='inputs'
                    name='last_name'
                    onChange={(e) => setFieldValue('lastname', e.target.value)}
                    value={values.lastname}
                    placeholder='Last Name...'
                  />
                </div>
              </div>
              <div className='flex my-[2rem] flex-col sm:flex-row justify-between'>
                <div className='w-full  mb-[2rem] sm:mb-0 sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='email'>
                    Email
                  </label>
                  <Input
                    className='inputs'
                    name='email'
                    type='email'
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    value={values.email}
                    placeholder='example@gmail.com'
                  />
                </div>
                <div className='w-full sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='subject'>
                    Subject Title
                  </label>
                  <Input
                    className='inputs'
                    name='subject'
                    onChange={(e) => setFieldValue('title', e.target.value)}
                    value={values.title}
                    placeholder='Healing, Marriage, Miracles...'
                  />
                </div>
              </div>
              <div className='flex my-[2rem] flex-col sm:flex-row justify-between'>
                <div className='w-full  mb-[2rem] sm:mb-0 sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='date_of_visit'>
                    Date of planned visit
                  </label>
                  <Input
                    className='inputs'
                    name='date_of_visit'
                    type='date'
                    onChange={(e) => {
                      setFieldValue('date_of_visit', e.target.value)
                    }}
                    value={values.date_of_visit}
                    placeholder={'Enter date of planned visit'}
                  />
                </div>
                <div className='w-full sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='phone'>
                    Phone Number
                  </label>
                  <Input
                    className='inputs'
                    name='phone'
                    type='number'
                    onChange={(e) => setFieldValue('phone', e.target.value)}
                    value={values.phone}
                    placeholder='Phone Number'
                  />
                </div>
              </div>
              <div className='flex my-[2rem] flex-col sm:flex-row justify-between'>
                <div className='w-full  mb-[2rem] sm:mb-0 sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='section'>
                    Message Section
                  </label>
                  <Select
                    className='inputs select'
                    defaultValue={'Testimony'}
                    name='section'
                    type='section'
                    onChange={(e) => handleClick(e)}
                    value={values.section}
                    placeholder='section'
                  >
                    <Option key={'Testimony'}>Testimony</Option>
                    <Option key={'inquires'}>Inquires</Option>
                    <Option key={'prayer Request'}>Prayer Request</Option>
                    <Option key={'Others'}>Others...</Option>
                  </Select>
                </div>
                <div className='w-full sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='Location'>
                    Location
                  </label>
                  <Input
                    className='inputs'
                    name='Location'
                    onChange={(e) => setFieldValue('country', e.target.value)}
                    value={values.country}
                    placeholder='Location...'
                  />
                </div>
              </div>
              <div className='flex my-4 flex-col'>
                <label className='font-bold mb-2' htmlFor='messages'>
                  Message
                </label>
                <textarea
                  onChange={(e) => setFieldValue('content', e.target.value)}
                  value={values.content}
                  className='outline-none border-2 resize-none p-5 h-[200px] rounded-lg'
                  placeholder='Type in your mesages here'
                ></textarea>
              </div>
              {Object.keys(errors).length > 0 &&
                Object.entries(errors).map(
                  (items, index) =>
                    typeof items[1] === 'string' && (
                      <div key={index} className={' h-10'}>
                        <small
                          className='text-base font-medium'
                          style={{ color: '#FF4D4F' }}
                        >
                          {items[1]}
                        </small>
                      </div>
                    )
                )}
              <Button
                loading={loading}
                style={{ width: '30%', margin: '3em auto 0' }}
                htmlType='submit'
                type='submit'
                className='about_button'
              >
                SHARE
              </Button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Connect
