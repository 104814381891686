// import { Button } from 'antd'
import React, { useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetBlogs } from '../api/api'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import f1 from '../assets/f1.png'
import f2 from '../assets/f2.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Arrow_right, fd1, fd2, fd3, fd4, fd5, fd6, Foundation } from '../asset'
// import CarouselContainer from '../components/Carousel'
import { blogContest } from '../context/blog'
import { FaChurch } from 'react-icons/fa'
import { BsFillPersonFill } from 'react-icons/bs'
import { BsFillPeopleFill } from 'react-icons/bs'

const FoundationPage = () => {
  const s = useNavigate()
  const [blogs, setBlogs] = useContext(blogContest)
  const getBlogs = GetBlogs()

  useEffect(() => {
    getBlogs((data) => setBlogs(data))
    AOS.init()
  }, [])
  return (
    <>
      <Navbar />
      {/* hero */}
      <section className='mt-10 mb-16 xl:mt-6 xl:mb-24   max-w-xs sm:max-w-md mx-auto font-sans md:max-w-4xl lg:max-w-5xl xl:max-w-7xl  px-4 md:px-6  lg:px-8 '>
        <div className='  max-w-xs sm:max-w-md mx-auto md:max-w-4xl lg:max-w-5xl xl:max-w-full px-4 md:px-6  lg:px-8'>
          {/* container */}
          <div className=' space-y-6 sm:space-y-10   md:flex justify-between items-center'>
            {/* text */}
            <div className=' space-y-4  lg:space-y-8 md:w-1/2'>
              <h1
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='200'
                className='font-black  font-mono text-center md:text-left text-3xl  max-w-sm mx-auto sm:text-4xl sm:max-w-full md:mx-0 text-babyblack leading-10 md:text-5xl md:max-w-sm sm:leading-[2.8rem] lg:text-5xl lg:leading-[3rem] xl:text-7xl xl:max-w-lg xl:leading-[5rem]'
              >
                Great Futures are built from a small charity
              </h1>
              <p className='text-center text-sm md:text-left max-w-xs mx-auto md:mx-0 text-babyblack leading-5 font-medium sm:text-sm  lg:max-w-sm md:text-base lg:text-lg  xl:max-w-md xl:text-xl'>
                Answer a call for hope today to assist a person in need. Your
                contribution can go a long way in the lives of these less
                priviledged ones. Feel free to reach out to us and as you do so
                may our Almighty God bless you abundantly
              </p>
              <div className='flex justify-center items-center mx-auto md:mx-0 md:items-start md:justify-start'>
                <button
                  onClick={() => s('/donate')}
                  className='bg-babypurple px-5 py-2 text-white lg:text-lg lg:px-6 lg:py-3 rounded shadow-white'
                >
                  Donate Now
                </button>
              </div>
            </div>
            {/* photo */}
            <div
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='400'
              className=' md:hidden flex justify-center items-center mx-auto relative md:w-1/2 py-6'
            >
              <img
                src={f2}
                alt='logo'
                width={1000}
                height={1000}
                className='max-w-xs sm:max-w-sm xl:max-w-md mx-auto  '
              />
            </div>
            <div
              data-aos='fade-left'
              data-aos-duration='2000'
              data-aos-delay='500'
              className=' hidden md:block relative md:w-1/2 pb-6 xl:pb-8'
            >
              <img
                src={f1}
                alt='logo'
                width={1000}
                height={1000}
                className='max-w-xs sm:max-w-sm xl:max-w-lg mx-auto  '
              />
            </div>
          </div>
        </div>
      </section>

      {/* get to know */}
      <section className='my-16 xl:my-40  max-w-xs sm:max-w-md mx-auto font-sans md:max-w-4xl lg:max-w-5xl xl:max-w-7xl  px-4 md:px-6  lg:px-8 '>
        {/* cont */}
        <div className='mt-16 md:mt-20 lg:mt-24 space-y-8 sm:space-y-12 md:space-y-0 md:flex items-center justify-between gap-16'>
          {/* image */}
          <div className='flex justify-center items-center md:w-1/2 '>
            <img
              src={Foundation}
              alt=''
              className='rounded-lg w-40 sm:w-56 sm:max-w-xs  md:max-w-md '
            />
          </div>
          {/* text */}
          <div className='md:w-1/2 space-y-4 md:space-y-5 xl:space-y-6'>
            {/* title */}
            <div className='flex flex-col justify-center items-center space-y-2 md:items-start '>
              <h1
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='200'
                className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0 md:text-left'
              >
                About <span className='text-babypurple'> Our Foundation </span>
              </h1>
              {/* top */}
              <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono md:text-left'>
                Franklin Tebid and Franklins Foundation
              </h2>
            </div>

            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='500'
              className='text-center sm:text-base md:text-sm md:text-left md:max-w-md lg:text-base xl:text-base'
            >
              James 1:27 God sees taking care of the orphans and widows of our
              society as the true practice of Love. Franklin Tebid and Franklins
              Foundation is all about taking the love of God to those in
              distress and need. God is Love and our foundation is here to show
              the God’s kind of Love! We believe when physical needs are met,
              the Spiritual needs will be met as well. It is difficult to preach
              the Evangelion to a needy person so Giving is the center of Love!
              (John 3:16).
            </h1>
          </div>
        </div>
      </section>
      {/* foundation division */}
      <section className='section-center space-y-12 xl:space-y-14 2xl:space-y-16'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Foundation <span className='text-babypurple'> Division</span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            These are main divisions we focus on
          </h2>
        </div>
        {/* values */}
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className=' space-y-8 flex flex-col justify-center items-center md:flex-row md:space-y-0 md:justify-between md:gap-4 xl:gap-6 2xl:gap-12'
        >
          {/* content 1 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-[20rem] md:w-1/3 xl:h-72'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <FaChurch className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl md:text-lg font-sans font-bold xl:text-2xl'>
              Provider Ministry
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              In everything I did, I showed you that by this kind of hard work
              we must help the weak, remembering the words the Lord Jesus
              himself said: ‘It is more blessed to give than to receive. (Acts
              20:35 NIV)
            </p>
          </div>
          {/* content 2 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-[20rem] md:w-1/3 xl:h-72'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <BsFillPersonFill className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl md:text-lg  font-sans font-bold xl:text-2xl'>
              Orphanage
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              Learn to do right; seek justice. Defend the oppressed. Take up the
              cause of the fatherless (Isaiah 1:17 NIV)
            </p>
          </div>
          {/* content 3 */}
          <div className='bg-white shadow-sm shadow-blue py-12 px-8 rounded-md space-y-2 block  xl:px-6 hover:-translate-y-8 duration-1000 max-w-xs lg:max-w-sm 2xl:max-w-md md:h-[20rem] xl:h-72 md:w-1/3'>
            <div className='bg-[#A303A0] px-3 py-3 inline-block rounded-md'>
              <BsFillPeopleFill className='text-2xl text-white' />
            </div>
            <h1 className='text-2xl md:text-lg  font-sans font-bold xl:text-2xl'>
              Widow / Widower
            </h1>
            <p className='text-[#757095] text-xs lg:text-sm 2xl:text-base font-sans'>
              When you are harvesting in your field and you overlook a sheaf, do
              not go back to get it. Leave it for the foreigner, the fatherless
              and the widow, so that the Lord your God may bless you in all the
              work of your hands (Deuteronomy 24:19).
            </p>
          </div>
        </div>
      </section>
      {/* our projects */}
      <section className='section-center'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Our <span className='text-babypurple'> Outreach</span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Read up on charity works we have conducted
          </h2>
        </div>
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className='flex flex-wrap flex-col md:grid md:grid-cols-2 pt-[2rem] justify-center items-center mx-auto md:gap-4 md:max-w-2xl  lg:grid-cols-3 lg:max-w-5xl'
        >
          {blogs
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((item, index) => (
              <div
                key={index}
                className='w-full p-4 shadow-md rounded-lg mb-[2rem] md:mb-0 max-w-xs'
              >
                <img
                  className='w-full h-[200px] object-cover object-top'
                  src={item?.image_url}
                  alt='gallery'
                />
                <h2 className='text-[#A303A0] mt-4 uppercase font-bold text-xl lg:text-2xl'>
                  {item?.title}
                </h2>
                <p className='my-5 text-sm'>
                  {item?.content?.slice(0, 250)}...
                </p>
                <Link
                  style={{ color: '#A303A0' }}
                  className='flex items-center font-bold mt-4'
                  to={`/blog/${item?._id}`}
                >
                  Read More{' '}
                  <img
                    style={{
                      width: '30px',
                      height: '10px',
                      objectFit: 'cover',
                      marginLeft: '5px',
                    }}
                    src={Arrow_right}
                    alt='arrow'
                  />
                </Link>
              </div>
            ))}
        </div>
      </section>
      {/* foundation gallery */}
      <section className='section-center space-y-16 xl:space-y-20 2xl:space-y-24'>
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Our <span className='text-babypurple'> Gallery</span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Familiarize yourself with images from our outreach sessions
          </h2>
        </div>
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className='space-y-8 flex flex-col justify-center items-center md:space-y-0 md:gap-6 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:gap-10 mx-auto'
        >
          {/* image 1 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm hover:shadow-veryLightGray   duration-1000'>
            <img src={fd1} alt='' className='' />
          </div>
          {/* image 2 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm    duration-1000'>
            <img src={fd2} alt='' className='     object-center' />
          </div>
          {/* image 3 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center hover:shadow-sm duration-1000'>
            <img src={fd3} alt='' className='' />
          </div>
          {/* image 4 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm duration-1000'>
            <img src={fd4} alt='' className='' />
          </div>
          {/* image 5 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm   '>
            <img src={fd5} alt='' className='' />
          </div>
          {/* image6 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm '>
            <img src={fd6} alt='' className='' />
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default FoundationPage
