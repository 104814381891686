import React, {createContext, useState} from 'react'

export const flyerContext = createContext()

export default function FlyerContextProvider (props){
    const [flyer, setFlyer] = useState([])
    const data = [flyer, setFlyer]

    return (
        <flyerContext.Provider value={data}>
            {props.children}
        </flyerContext.Provider>
      )
}

