import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { Chat } from '../src/asset'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import About from './pages/About'
import Connect from './pages/Connect'
import Donate from './pages/Donate'
import Live from './pages/Live'
import Error from './pages/Error'
import Foundation from './pages/Foundation'
import SingleBlog from './pages/SingleBlog'
import Douala from './pages/Douala'
import Home from './pages/Home'
import { Popover } from 'antd'

function Screen() {
  const [visible, setVisible] = useState(false)
  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible)
  }
  return (
    <div className='relative overflow-hidden bg-[rgb(249, 250, 241)]'>
      <div className='chatBtn '>
        <div id='pop' className='relative w-full h-full '>
          <div className='main shadow-inner rounded border-3 border-babypurple flex items-center justify-center cursor-pointer'>
            <Popover
              content={
                <>
                  <h2 className='text-sm text-center '>
                    Hi there, reach out to us now
                  </h2>
                  <a
                    href='https://wa.me/+15129953189'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='bg-babypurple text-white font-semibold rounded-lg animate-pulse shadow-xl p-3 flex items-center justify-center cursor-pointer'
                  >
                    Chat Now
                  </a>
                </>
              }
              title={null}
              destroyTooltipOnHide={true}
              getPopupContainer={() => document.querySelector('#pop')}
              trigger='click'
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <img
                className='w-[40px] animate-pulse relative h-[40px] text-[#A303A0]'
                src={Chat}
                alt='chat'
              />
            </Popover>
          </div>
        </div>
      </div>

      <ToastContainer />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/connect' element={<Connect />} />
        <Route path='/about' element={<About />} />
        <Route path='/doualacenter' element={<Douala />} />
        <Route path='/live' element={<Live />} />
        <Route path='/foundation' element={<Foundation />} />
        <Route path='/donate' element={<Donate />} />
        <Route path='*' element={<Error />} />
        <Route path='/blog/:id' element={<SingleBlog />} />
      </Routes>
    </div>
  )
}

export default Screen
