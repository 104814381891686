import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import dd2 from '../assets/dd2.png'
import dd3 from '../assets/dd3.png'
import ddd3 from '../assets/ddd3.png'
import ddd1 from '../assets/ddd1.png'
import ddd2 from '../assets/ddd2.png'
import { HiPhoneMissedCall } from 'react-icons/hi'
import { ImLocation2 } from 'react-icons/im'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Footer from '../components/Footer'
function Douala() {
  useEffect(() => {
    AOS.init()
  }, [])
  return (
    <>
      <Navbar />
      <div className='backdd-imagee w-full h-20 md:h-24 lg:h-28 flex items-center'>
        <h1
          data-aos='fade-left'
          data-aos-duration='5000'
          data-aos-delay='200'
          className='text-white  font-mono px-6 text-2xl md:text-3xl xl:text-4xl'
        >
          Douala Center
        </h1>
      </div>
      {/* pastors */}
      <section className='section-center space-y-6 xl:space-y-10 2xl:space-y-12'>
        {/* cont */}
        <div className='mt-16 md:mt-10 lg:mt-16 space-y-8 sm:space-y-12 md:space-y-0 md:flex items-center justify-between gap-6 xl:gap-14'>
          {/* image */}
          <div className='flex justify-center items-center md:w-1/2 '>
            <img
              src={dd2}
              alt=''
              className='rounded-lg w-54 sm:w-full sm:max-w-xs md:max-w-full  '
            />
          </div>
          {/* text */}
          <div className='md:w-1/2 space-y-4 md:space-y-5 xl:space-y-6'>
            {/* headline */}
            <div>
              <h1
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='200'
                className='text-babyblack text-xl font-bold relative w-max sm:text-2xl md:text-xl lg:text-2xl mx-auto  xl:text-3xl text-center md:text-left md:mx-0'
              >
                Resident
                <span className='text-[#A303A0]'> Pastors </span>
              </h1>
              <h1 className='text-center text-base text-babyblack  font-bold sm:text-xl  md:text-left xl:text-3xl md:max-w-xs xl:max-w-full font-mono tracking-wide'>
                Pastor Kingsley and Pastor Mrs Glory Tikum
              </h1>
            </div>

            <h1
              data-aos='fade-up'
              data-aos-duration='2000'
              data-aos-delay='500'
              className='text-justify sm:text-sm  sm:max-w-md md:text-xs md:text-left md:max-w-xl lg:text-sm lg:max-w-lg  xl:text-base xl:max-w-xl  2xl:text-base'
            >
              Pastor Kingsley and Pastor Glory Tikum are a dynamic and devoted
              couple serving as the resident pastors of Glorious Nation Church
              International in Douala Cameroon. Their unwavering commitment to
              their faith, genuine love for their congregation, and passion for
              spreading the Gospel of Jesus Christ have made them highly
              respected figures in the community. Pastor Kingsley Tikum hail
              from Bessi Awum village, Batibo sub division, a small town in
              Cameroon. From a young age, he displayed a deep interest in
              spirituality and had a strong desire to make a positive impact on
              people's lives. His early exposure to the teachings of the Bible
              in the Catholic where he served as a lay preacher further nurtured
              his spiritual growth. As a teenager, Pastor Kingsley developed a
              passion for ministry and felt a strong calling to become a pastor.
              Married to Pastor Glory Tikum, who hails from Bamendankwe from a
              Christian home who is as well driven by the same passion for
              taking the Gospel of Jesus Christ to the ends of the earth. They
              currently have two amazing kids whom they are passionately raising
              in the way and the fear of the Lord. Have you been in Douala or
              visiting and looking for a place of worship and fellowship with
              God? Search no further. These two are here with the leadership of
              the Holy Spirit and their incredible team to create an atmosphere
              of Jesus.
            </h1>
          </div>
        </div>
      </section>
      {/* upcoming Events */}
      <section className='section-center'>
        {/* bidy */}
        <div>
          {/* cont */}
          <div className=' section-center my-20 space-y-10 md:flex md:justify-between md:items-center md:space-y-0 md:gap-6 overflow-hidden'>
            {/* image */}
            <div
              data-aos='fade-left'
              data-aos-duration='2000'
              data-aos-delay='400'
              className='  relative md:w-1/2'
            >
              <img
                src={dd3}
                alt='logo'
                width={1000}
                height={1000}
                className='w-72  sm:w-80 md:w-full max-w-xs sm:max-w-sm xl:max-w-lg mx-auto '
              />
            </div>
            {/* text */}
            <div className='font-sans flex flex-col justify-center items-center mx-auto md:mx-0 md:items-start space-y-6 md:w-1/2 '>
              {/* headline */}
              <div>
                <h1
                  data-aos='fade-up'
                  data-aos-duration='2000'
                  data-aos-delay='200'
                  className='text-babyblack font-bold relative w-max sm:text-2xl md:text-xl lg:text-2xl mx-auto  xl:text-3xl text-center md:text-left md:mx-0'
                >
                  Service
                  <span className='text-[#A303A0]'> Schedule </span>
                </h1>
                <h1 className='text-center text-babyblack  font-bold sm:text-xl  md:text-left xl:text-3xl md:max-w-xs xl:max-w-md font-mono'>
                  Here’s a list of our upcoming events at the Douala center
                </h1>
              </div>
              {/* paragraph */}
              <p className='text-babyblack text-xs  text-center sm:text-sm sm:leading-5 md:text-left md:leading-5 xl:text-base xl:max-w-md'>
                Listed below are our service schedules at the Douala satellite
                church. as you attend may our almighty God bless you. Amen
              </p>
              {/* details */}
              <div
                data-aos='fade-up'
                data-aos-duration='2000'
                data-aos-delay='500'
                className='md:max-w-xs xl:max-w-sm space-y-4  sm:space-y-8'
              >
                {/* one */}
                <div className='bg-white shadow-md shadow-babygreen flex gap-4 items-start px-4 py-6 md:hover:translate-x-6 hover:shadow-xl duration-1000'>
                  {/* icon */}
                  <div className='p-2 bg-babypurple w-max rounded-sm  text-white font-bold  '>
                    Sun
                  </div>
                  {/* text */}
                  <div className='space-y-1'>
                    <h1 className=' text-base font-bold'>Sunday Service</h1>
                    <p className='text-xs'>
                      Join us every Sunday at{' '}
                      <span className='font-bold'> 9am</span> as we praise,
                      worship and share the word of God in fellowship.
                    </p>
                  </div>
                </div>

                {/* two */}
                <div className='bg-white shadow-md shadow-babygreen flex gap-4 items-start px-4 py-6 md:hover:translate-x-6 hover:shadow-xl duration-1000'>
                  {/* icon */}
                  <div className='p-2 bg-babypurple w-max rounded-sm  text-white font-bold  '>
                    Wed
                  </div>
                  {/* text */}
                  <div className='space-y-1'>
                    <h1 className=' text-base font-bold'>Glorius Word</h1>
                    <p className='text-xs'>
                      Join us every Wednesday at{' '}
                      <span className='font-bold'>6pm </span> as we dig deep and
                      enlighten our members about the inner secrets of God’s
                      word
                    </p>
                  </div>
                </div>
                {/* three*/}
                <div className='bg-white shadow-md shadow-babygreen flex gap-4 items-start px-4 py-6 md:hover:translate-x-6 hover:shadow-xl duration-1000'>
                  {/* icon */}
                  <div className='p-2 bg-babypurple w-max rounded-sm  text-white font-bold  '>
                    Fri
                  </div>
                  {/* text */}
                  <div className='space-y-1'>
                    <h1 className=' text-base font-bold'>
                      Glorious Night Service
                    </h1>
                    <p className='text-xs'>
                      Join us every Friday Night at{' '}
                      <span className='font-bold'> 6pm</span> as we pray and
                      worship the most high God as his mercies endureds for
                      ever. This promises to be powered packed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Get in touch*/}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        {/* title */}
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Get in <span className='text-babypurple'> Touch </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Feel free to get in touch with us in any of the under listed mediums
          </h2>
        </div>
        {/* how */}
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='400'
          className='flex flex-col justify-center items-center space-y-6 sm:flex-row  sm:space-y-0 sm:gap-6 '
        >
          {/* how1 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 sm:w-52 sm:h-48 md:h-52 lg:h-60  lg:w-64 xl:max-w-sm flex flex-col justify-start items-center'>
            {/* icon */}
            <div className='bg-babypurple  w-10 h-10 rounded-full flex justify-center items-center'>
              <HiPhoneMissedCall className='text-white text-xl' />
            </div>
            <h1 className='font-bold text-lg lg:text-xl text-center '>
              Call Us
            </h1>
            <p className='text-center text-xs w-40 xl:w-full lg:text-base  '>
              +237680561172
            </p>
          </div>
          {/* how2 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 sm:w-52 sm:h-48 md:h-52 lg:h-60 lg:w-64 xl:max-w-sm flex flex-col justify-start items-center'>
            {/* icon */}
            <div className='bg-babypurple   w-10 h-10 rounded-full flex justify-center items-center'>
              <ImLocation2 className='text-white text-xl' />
            </div>

            <h1 className='font-bold text-lg text-center lg:text-xl'>
              Visit Us
            </h1>
            <p className='text-center text-xs w-full lg:text-base '>
              Glorious Nation Church Douala Bonaberi behind fokou opposite MTN
              antenna
            </p>
          </div>
        </div>
      </section>
      {/* gallery */}
      <section className='section-center space-y-16 xl:space-y-20 2xl:space-y-24'>
        <div className='flex flex-col justify-center items-center space-y-2 '>
          <h1
            data-aos='fade-up'
            data-aos-duration='2000'
            data-aos-delay='200'
            className='text-babyblue font-extrabold relative w-max sm:text-2xl md:text-xl lg:text-2xl  xl:text-3xl mb-0'
          >
            Our <span className='text-babypurple'> Gallery </span>
          </h1>
          {/* top */}
          <h2 className='text-center text-babyblack  font-bold sm:text-lg   md:text-xl lg:text-2xl  xl:text-3xl sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg  font-mono'>
            Familiarize yourself with images from our church sessions
          </h2>
        </div>
        <div
          data-aos='fade-up'
          data-aos-duration='2000'
          data-aos-delay='500'
          className='space-y-8 flex flex-col justify-center items-center md:space-y-0 md:gap-6 md:grid md:grid-cols-2 lg:grid-cols-3 lg:gap-8 xl:gap-10 mx-auto'
        >
          {/* image 1 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm hover:shadow-veryLightGray   duration-1000'>
            <img src={ddd1} alt='' className='' />
          </div>
          {/* image 2 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center  hover:shadow-sm    duration-1000'>
            <img src={ddd2} alt='' className='     object-center' />
          </div>
          {/* image 3 */}
          <div className='max-w-xs xl:max-w-full flex mx-auto justify-center items-center hover:shadow-sm duration-1000'>
            <img src={ddd3} alt='' className='' />
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Douala
