import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import n1 from '../assets/n1.png'
import n2 from '../assets/n2.png'
import n3 from '../assets/n3.png'
import n4 from '../assets/n4.png'
function SampleNextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className=' hidden w-10 h-10 absolute -right-10  md:-right-5 xl:-right-5 rounded-full  justify-center items-center'
      style={{
        ...style,
        display: 'flex',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      onClick={onClick}
    ></div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className=' hidden w-10 h-10 absolute -left-10 md:-left-5 xl:-left-5 rounded-fulljustify-center items-center'
      style={{
        ...style,
        display: 'flex',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      onClick={onClick}
    ></div>
  )
}

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 5000,
      autoplaySpeed: 7000,
      cssEase: 'linear',
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    }
    return (
      <div className='w-full relative overflow-x-hidden overflow-y-hidden md:rounded-br-[4rem] lg:rounded-br-[6rem] xl:rounded-br-[8rem] '>
        <Slider {...settings}>
          <img
            src={n1}
            alt='logo'
            width={1000}
            height={1000}
            className='w-72 sm:max-w-full md:max-w-full md:w-full  '
          />
          <img
            src={n2}
            alt='logo'
            width={1000}
            height={1000}
            className='w-72 sm:max-w-full md:max-w-full md:w-full  '
          />
          <img
            src={n3}
            alt='logo'
            width={1000}
            height={1000}
            className='w-72 sm:max-w-full md:max-w-full md:w-full  '
          />
          <img
            src={n4}
            alt='logo'
            width={1000}
            height={1000}
            className='w-72 sm:max-w-full md:max-w-full md:w-full  '
          />
        </Slider>
      </div>
    )
  }
}
