import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { blogContest } from '../context/blog'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'

const SingleBlog = () => {
  const { id } = useParams()
  const [mainBlog, setMainBlog] = useState([])
  const [blog] = useContext(blogContest)

  useEffect(() => {
    const m = blog.filter((item) => item._id === id)
    setMainBlog(m)
  }, [id, blog])
  return (
    <>
      <div className='bg-babypurple px-6 py-2  lg:px-8 lg:py-3 inline-block mx-6 my-3 cursor-pointer'>
        {' '}
        <Link to='/foundation'>
          {' '}
          <h1 className='font-bold text-white mb-0 '>Return Home</h1>
        </Link>
      </div>
      <section className='mb-16 xl:mb-40 max-w-xs sm:max-w-md mx-auto font-sans md:max-w-4xl lg:max-w-5xl xl:max-w-7xl  px-4 md:px-6  lg:px-8'>
        {mainBlog.length > 0 &&
          mainBlog.map((item, index) => (
            <div key={index} className='flex-col flex'>
              <div className='my-[3em]'>
                <h1 className='font-bold text-2xl lg:text-5xl capitalize mb-10'>
                  {item.title}
                </h1>
                <img
                  src={item.image_url}
                  style={{
                    width: '100%',
                    height: '350px',
                    objectFit: 'cover',
                    object: 'top',
                  }}
                  alt='blog'
                />
              </div>
              <div className='mt-[3em] text-justify'>{item.content}</div>
            </div>
          ))}
      </section>
      <Footer />
    </>
  )
}

export default SingleBlog
