import { PayPalButtons } from '@paypal/react-paypal-js'
import * as yup from 'yup'
import { Button, Input, Modal } from 'antd'
import { useFormik } from 'formik'
import React, { useState } from 'react'
// import Modal from 'react-modal';
import { Modalpic } from '../asset'
import { CreateTransaction } from '../api/api'

const PaymentModal = ({ opening, closing, setIsPaid }) => {
  const [modalIsOpen, setIsOpen] = React.useState(opening)
  const [toPay, setToPay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const createTransaction = CreateTransaction()
  const initialValues = {
    fullname: '',
    email: '',
    purpose: '',
    amount: '',
  }
  const handleSubmit = (data) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setToPay(true)
    }, 3000)
  }
  const paymentSchema = yup.object({
    fullname: yup.string().required('Kindly include a name'),
    email: yup.string().required('Kindly include an email'),
    purpose: yup.string().required('Kindly state your purpose'),
    amount: yup.string().required('Kindly include an amount'),
  })
  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: paymentSchema,
  })

  const { setFieldValue, values, errors } = formik

  const handleApprove = (id, order) => {
    const payload = {
      name: values.fullname,
      email: values.email,
      purpose: values.purpose,
      amount: values.amount,
      transaction_id: id,
      status: order.status,
    }
    createTransaction(payload, () => {
      setToPay(false)
      closeModal()
      setIsPaid(true)
    })
  }

  function closeModal() {
    setIsOpen(false)
    closing(false)
  }
  return (
    <div>
      <Modal
        bodyStyle={{
          padding: 0,
        }}
        centered
        width={'80%'}
        footer={null}
        title={null}
        visible={modalIsOpen}
        onOk={closeModal}
        onCancel={closeModal}
      >
        <div className='w-full flex'>
          <div className='w-1/2  hidden flex-1 md:block max-h-[600px]'>
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={Modalpic}
              alt='modal-pic'
            />
          </div>
          <div className='flex w-full md:w-[50%] h-full p-6 flex-col'>
            <h1 className='font-bold mb-2 text-2xl md:text-5xl'>
              Give <span className='text-red-600'>cheerfully</span>{' '}
            </h1>
            <p className='text-xs md:text-sm mb-1 font-medium'>
              “Each man should give what he has decided in his heart to give,
              not reluctantly or under compulsion, for God loves a cheerful
              giver”
            </p>
            <p className='test-xs text-red-600 mb-0'>
              Don’t forget to indicate your name and purpose of giving
            </p>
            <form
              onSubmit={formik.handleSubmit}
              className='w-full flex flex-col'
            >
              <div className='flex my-2 flex-col sm:flex-row justify-between'>
                <div className='w-full mb-[2rem] sm:mb-0 sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='fullname'>
                    Full Name
                  </label>
                  <Input
                    name='fullname'
                    value={values.fullname}
                    onChange={(e) => setFieldValue('fullname', e.target.value)}
                    placeholder='FullName...'
                  />
                </div>
                <div className='w-full sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='email'>
                    Email
                  </label>
                  <Input
                    name='email'
                    type='email'
                    value={values.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                    placeholder='Email...'
                  />
                </div>
              </div>
              <div className='flex my-[1rem] flex-col sm:flex-row justify-between'>
                <div className='w-full  mb-[2rem] sm:mb-0 sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='purpose'>
                    Purpose
                  </label>
                  <Input
                    name='purpose'
                    value={values.purpose}
                    onChange={(e) => setFieldValue('purpose', e.target.value)}
                    placeholder='Purpose'
                  />
                </div>
                <div className='w-full sm:w-[45%] flex flex-col'>
                  <label className='font-bold mb-2' htmlFor='amount'>
                    Amount (USD)
                  </label>
                  <Input
                    name='amount'
                    type='number'
                    value={values.amount}
                    onChange={(e) => setFieldValue('amount', e.target.value)}
                    placeholder='Amount...'
                  />
                </div>
              </div>
              {Object.keys(errors).length > 0 &&
                Object.entries(errors).map(
                  (items, index) =>
                    typeof items[1] === 'string' && (
                      <div key={index}>
                        <small className='text-xs' style={{ color: '#FF4D4F' }}>
                          {items[1]}
                        </small>
                      </div>
                    )
                )}
              <div className='w-4/6 md:w-1/2 mt-0 md:mt-5 mx-auto'>
                {!toPay && (
                  <Button
                    loading={loading}
                    type='submit'
                    style={{
                      borderRadius: '8px',
                      backgroundColor: '#CE1B1E',
                      color: 'white',
                      height: '2.5rem',
                      fontWeight: '600',
                      width: '100%',
                    }}
                    htmlType='submit'
                  >
                    Donate
                  </Button>
                )}
                {toPay && (
                  <PayPalButtons
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        intent: 'CAPTURE',
                        purchase_units: [
                          {
                            description: values.purpose,
                            amount: {
                              currency_code: 'USD',
                              value: values.amount,
                            },
                          },
                        ],
                      })
                    }}
                    onApprove={async (data, actions) => {
                      const order = await actions.order.capture()
                      handleApprove(data.orderID, order)
                    }}
                    onCancel={() => {
                      setToPay(false)
                    }}
                    onError={(err) => {
                      setError(err)
                    }}
                  />
                )}
              </div>
            </form>
            <div className='self-center text-center flex flex-col items-center mt-2 justify-self-end'>
              <small className='text-xs mb-1'>Secure credit card payment</small>
              <small className='text-xs mb-3'>
                This is a secure 128 -bit ssl encrypted payment
              </small>
              <small className='text-xs text-red-600'>
                Please note that all donations are non-refundable
              </small>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PaymentModal
