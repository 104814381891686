import React, {createContext, useState} from 'react'

export const testimonyContest = createContext()

export default function TestimonyContestProvider (props){
    const [testimonies, setTestimonies] = useState([])
    const data = [testimonies, setTestimonies]

    return (
        <testimonyContest.Provider value={data}>
            {props.children}
        </testimonyContest.Provider>
      )
}

