import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { logo } from '../asset'
import { BsFillPlayFill } from 'react-icons/bs'
function Navbar() {
  const [active, setActive] = useState(false)
  const [isAbout, setIsAbout] = useState(false)
  const router = useNavigate()
  const location = useLocation()
  const { pathname } = useLocation()
  const handleClick = (e) => {
    setActive(!active)
  }
  const handleRoute = (value) => {
    router(value)
    setActive((val) => !val)
  }

  useEffect(() => {
    if (
      location.pathname === '/connect' ||
      location.pathname === '/donate' ||
      location.pathname === '/foundation' ||
      location.pathname === '/doualacenter' ||
      location.pathname.includes('/blog/')
    ) {
      setIsAbout(true)
    } else {
      setIsAbout(false)
    }
    return () => {
      setIsAbout(false)
    }
  }, [location])
  return (
    <>
      <div className=' px-6  py-8 flex justify-between items-center font-sans '>
        {/*logo */}
        <Link to='/'>
          <div className='flex   justify-between items-center'>
            {' '}
            <img
              className='mr-2 lg:mr-4 xl:mr-6 logo w-12  md:w-14 xl:w-16  '
              src={logo}
              alt='logo'
            />
            <h1
              className={`${
                isAbout
                  ? 'text-babyblack font-bold md:text-base lg:text-lg '
                  : 'text-white font-bold md:text-base lg:text-lg '
              }`}
            >
              Glorious Nation
            </h1>
          </div>
        </Link>

        {/* web links */}
        <div className='flex items-center justify-between  md:gap-6 lg:gap-8 xl:gap-14'>
          {/* about */}
          <div className=''>
            <Link
              to='/about'
              className={`${
                isAbout
                  ? 'text-babyblack hidden md:flex font-bold text-xs lg:text-sm xl:text-base'
                  : pathname === '/about'
                  ? 'text-white hidden md:flex font-bold text-xs lg:text-sm xl:text-base border-white border-b-2'
                  : 'text-white hidden md:flex font-bold text-xs lg:text-sm xl:text-base'
              }`}
            >
              About Us
            </Link>
          </div>
          {/* Contact */}
          <div className=''>
            <Link
              to='/connect'
              className={`${
                isAbout && pathname === '/connect'
                  ? 'text-babyblack hidden md:flex font-bold border-babypurple border-b-2 text-xs lg:text-sm xl:text-base'
                  : isAbout
                  ? 'text-babyblack hidden md:flex font-bold  text-xs lg:text-sm xl:text-base'
                  : 'text-white hidden md:flex font-bold text-xs lg:text-sm xl:text-base'
              }`}
            >
              Contact Us
            </Link>
          </div>
          {/* Douala */}
          <div className=''>
            <Link
              to='/doualacenter'
              className={`${
                isAbout && pathname === '/doualacenter'
                  ? 'text-babyblack hidden md:flex font-bold border-babypurple border-b-2 text-xs lg:text-sm xl:text-base'
                  : isAbout
                  ? 'text-babyblack hidden md:flex font-bold  text-xs lg:text-sm xl:text-base'
                  : 'text-white hidden md:flex font-bold text-xs lg:text-sm xl:text-base'
              }`}
            >
              Douala Center
            </Link>
          </div>
          {/* foundation */}
          <div className=''>
            <Link
              to='/foundation'
              className={`${
                isAbout && pathname === '/foundation'
                  ? 'text-babyblack hidden md:flex font-bold border-babypurple border-b-2 text-xs lg:text-sm xl:text-base'
                  : isAbout
                  ? 'text-babyblack hidden md:flex font-bold  text-xs lg:text-sm xl:text-base'
                  : 'text-white hidden md:flex font-bold text-xs lg:text-sm xl:text-base'
              }`}
            >
              Our Foundation
            </Link>
          </div>
          {/* Give */}
          <div className=''>
            <Link
              to='/donate'
              className={`${
                isAbout && pathname === '/donate'
                  ? 'text-babyblack hidden md:flex font-bold border-babypurple border-b-2 text-xs lg:text-sm xl:text-base'
                  : isAbout
                  ? 'text-babyblack hidden md:flex font-bold  text-xs lg:text-sm xl:text-base'
                  : 'text-white hidden md:flex font-bold text-xs lg:text-sm xl:text-base'
              }`}
            >
              Give
            </Link>
          </div>
        </div>
        {/* live stream */}
        <Link
          className={`${
            isAbout
              ? 'hidden md:flex text-babyblack border border-babyblack px-2 lg:px-4  xl:px-6  py-2  rounded group hover:bg-babypurple '
              : 'hidden md:flex text-white border border-white px-2 lg:px-4  xl:px-6  py-2  rounded group hover:bg-white  duration-1000 '
          }`}
          to='/live'
        >
          <div className='flex items-center justify-center gap-1'>
            <BsFillPlayFill
              className={`${
                isAbout
                  ? 'text-lg lg:text-2xl xl:text-3xl text-babyblack group-hover:text-white duration-1000 '
                  : 'text-lg lg:text-2xl xl:text-3xl text-white group-hover:text-babypurple duration-1000 '
              }`}
            />
            <h1
              className={`${
                isAbout
                  ? 'text-xs lg:text-base xl:text-lg font-bold text-babyblack  leading-none group-hover:text-white duration-1000 mb-0  '
                  : 'text-xs lg:text-base xl:text-lg font-bold text-white group-hover:text-babypurple leading-none duration-1000 mb-0 '
              }`}
            >
              {' '}
              Watch us live
            </h1>
          </div>
        </Link>
        {/* mobile */}
        {active ? (
          <button
            id='menu-btn'
            className='z-50 open block md:hidden focus:outline-none hamburger cursor-pointer'
            onClick={() => setActive(!active)}
          >
            <span className='hamburger-top'></span>
            <span className='hamburger-middle'></span>
            <span className='hamburger-bottom'></span>
          </button>
        ) : (
          <button
            id='menu-btn'
            className=' z-30 block md:hidden focus:outline-none hamburger cursor-pointer'
            onClick={() => setActive(!active)}
          >
            <span
              className={`${isAbout ? 'hamburger-top' : 'phamburger-top'}`}
            ></span>
            <span
              className={`${
                isAbout ? 'hamburger-middle' : 'phamburger-middle'
              }`}
            ></span>
            <span
              className={`${
                isAbout ? 'hamburger-bottom' : 'phamburger-bottom'
              }`}
            ></span>
          </button>
        )}
        {/* new  mobile*/}
        {active ? (
          <div
            id='menu'
            className='fixed inset-0 z-20  flex-col items-center self-end w-full h-full m-h-screen px-6 py-1 pt-32 pb-4 tracking-widest  uppercase divide-y divide-gray-500 bg-opacity-95 bg-veryLightGray font-sans text-white'
          >
            <div className='w-full py-3 text-center text-white'>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  router('/')
                  setActive(!active)
                  // handleRoute("/")
                }}
                to='/'
                className=' text-white'
              >
                Home
              </Link>
            </div>
            <div className='w-full py-3 text-center'>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  handleRoute('/about')
                }}
                to='/about'
                className='block hover:text-softRed'
              >
                About Us
              </Link>
            </div>
            <div className='w-full py-3 text-center'>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  handleRoute('/connect')
                }}
                to='/connect'
                className='block hover:text-softRed'
              >
                Contact Us
              </Link>
            </div>
            <div className='w-full py-3 text-center'>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  handleRoute('/doualacenter')
                }}
                to='/douala center'
                className='block hover:text-softRed'
              >
                Douala Center
              </Link>
            </div>
            <div className='w-full py-3 text-center'>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  handleRoute('/foundation')
                }}
                to='/foundation'
                className='block hover:text-softRed'
              >
                Our Foundation
              </Link>
            </div>
            <div className='w-full py-3 text-center'>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  handleRoute('/donate')
                }}
                to='/donate'
                className='block hover:text-softRed'
              >
                Give
              </Link>
            </div>
            <div className='w-full py-3 text-center'>
              <Link
                onClick={(e) => {
                  e.preventDefault()
                  handleRoute('/live')
                }}
                to='/live'
                className='block hover:text-softRed'
              >
                Watch us Live
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default Navbar
